import { useEffect } from 'react'
import { useGeolocated } from 'react-geolocated'
import { setUserCoordinates } from '../../services/storage/storageService'
import moment from 'moment'

export default function useGetCurrentLocation(suppressLocationOnMount: boolean) {
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    suppressLocationOnMount: suppressLocationOnMount,
    userDecisionTimeout: 5000
  })

  useEffect(() => {
    if (coords) {
      setUserCoordinates({
        lat: coords.latitude,
        lng: coords.longitude,
        updatedAt: moment().unix()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords?.latitude, coords?.longitude])

  return { coords }
}