import { Box, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import TraineeAvatarImage from '../../../assets/images/traineeAvatar.svg'
import { Trainee } from '../../../types/trainees'
import TraineeCardViewActions from './TraineeCardViewActions'
import UnlinkTraineeDialog from '../../dialogs/UnlinkTraineeDialog'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  trainee: Trainee
}

export default function TraineeCardView(props: Props) {
  const navigate = useNavigate()
  const theme = useTheme()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  return (
    <>
      <UnlinkTraineeDialog
        open={isDialogOpen}
        uuid={props.trainee.uuid}
        name={props.trainee.fullName}
        onClose={() => { setIsDialogOpen(false) }}
      />
      <Card sx={{ position: 'relative', maxWidth: '100%' }}>
        <CardContent
          sx={{
            cursor: 'pointer'
          }}
          onClick={() => {
            navigate(`/trainees/${props.trainee.uuid}`)
          }}
        >
          <Grid
            container
            gap='1rem'
            flexWrap='nowrap'
            alignItems='center'
            paddingRight='2rem'
          >
            <Box
              component='img'
              src={TraineeAvatarImage}
              sx={{
                marginTop: '-.125rem',
                [theme.breakpoints.up('md')]: {
                  width: '3rem',
                  height: '3rem'
                },
                [theme.breakpoints.down('md')]: {
                  width: '3rem',
                  height: '3rem'
                },
                [theme.breakpoints.down('sm')]: {
                  width: '2.25rem',
                  height: '2.25rem'
                }
              }}
            />
            <Typography
              variant='body1'
              fontWeight={700}
            >
              {props.trainee.fullName}
            </Typography>
          </Grid>
        </CardContent>
        <TraineeCardViewActions 
          trainee={props.trainee}
          onUnlink={() => {
            setIsDialogOpen(true)
          }}
        />
      </Card>
    </>

  )
}