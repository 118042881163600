import { Grid, useTheme } from '@mui/material'
import { Location } from '../../../types/locations'
import LocationCardView from './LocationCardView'
import EmptyListView from '../../common/EmptyListView'
import { useTranslation } from 'react-i18next'

type Props = {
  locations: Location[]
}

export default function ListView(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('locationList').t

  if (props.locations.length === 0) {
    return (
      <Grid marginTop='4rem' width='100%'>
        <EmptyListView 
          title={texts('no_data_title')}
          description={texts('no_data_description')}
        />
      </Grid>
    )
  }

  return (
    <Grid
      display='grid'
      sx={{
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
          rowGap: '1.25rem',
          columnGap: '1.25rem'
        },
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
          rowGap: '1.25rem',
          columnGap: '1.25rem'
        },
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: 'repeat(1, 1fr)',
          rowGap: '.75rem',
          columnGap: '0'
        }
      }}
    >
      {
        props.locations.map(e => (
          <LocationCardView 
            key={e.uuid}
            location={e}
          />
        ))
      }
    </Grid>
  )
}