import { Box, Grid, Typography, useTheme } from '@mui/material'
import EmptyViewImage from '../../assets/images/emptyView.webp'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'

type Props = {
  image?: string
  title: string
  description: string
}

export default function EmptyListView(props: Props) {
  const theme = useTheme()
  const { isDesktop } = useGetDeviceType()

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      gap='.5rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          gap: '1rem'
        },
        [theme.breakpoints.down('md')]: {
          gap: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
          gap: '.5rem'
        },
        textAlign: 'center'
      }}
    >
      <Box
        component='img'
        src={props.image ?? EmptyViewImage}
        sx={{
          marginBottom: '.5rem',
          [theme.breakpoints.up('md')]: {
            width: '6.25rem',
            height: '6.25rem'
          },
          [theme.breakpoints.down('md')]: {
            width: '6.25rem',
            height: '6.25rem'
          },
          [theme.breakpoints.down('sm')]: {
            width: '4rem',
            height: '4rem'
          }
        }}
      />
      <Typography
        variant={isDesktop ? 'h4' : 'h3'}
        component='p'
      >
        {props.title}
      </Typography>
      <Typography variant='body1'>
        {props.description}
      </Typography>
    </Grid>
  )
}

