import { Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { LocationListListSearchParams, LocationListType } from '../../../types/locations'
import SelectButton from './SelectButton'
import { GridView, MapOutlined } from '@mui/icons-material'
import SearchInput from '../../customMui/SearchInput'
import { queryNames } from '../../../hooks/queries'
import { useQueryClient } from '@tanstack/react-query'

export default function TopSection() {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const texts = useTranslation('locationList').t

  const [searchParams, setSearchParams] = useQueryParams<LocationListListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined),
    'list-type': withDefault(StringParam, LocationListType.LIST)
  })

  return (
    <Grid
      container
      justifyContent='space-between'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: '1.5rem'
        }
      }}
    >
      <Typography variant='h2'>
        {texts('title')}
      </Typography>
      <Grid
        container
        justifyContent='flex-end'
        gap='.5rem'
        sx={{
          [theme.breakpoints.up('md')]: {
            marginTop: '1rem'
          },
          [theme.breakpoints.down('md')]: {
            marginTop: '1rem'
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: '1.5rem'
          }
        }}
      >
        <SelectButton 
          selected={searchParams['list-type'] === LocationListType.LIST}
          onClick={() => {
            setSearchParams({
              page: 1,
              search: undefined,
              'list-type': LocationListType.LIST
            }, 'replaceIn')
          }}
        >
          <Grid
            container
            alignItems='center'
            gap='.5rem'
          >
            <GridView 
              sx={{
                marginTop: '-.125rem',
                [theme.breakpoints.up('sm')]: {
                  fontSize: '1.375rem'
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: '1.25rem'
                }
              }}
            />
            {texts('list_button')}
          </Grid>
        </SelectButton>
        <SelectButton
          selected={searchParams['list-type'] === LocationListType.MAP}
          onClick={() => {
            setSearchParams({
              page: 1,
              search: undefined,
              'list-type': LocationListType.MAP
            }, 'replaceIn')
          }}
        >
          <Grid
            container
            alignItems='center'
            gap='.5rem'
          >
            <MapOutlined 
              sx={{
                marginTop: '-.125rem',
                [theme.breakpoints.up('sm')]: {
                  fontSize: '1.375rem'
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: '1.25rem'
                }
              }}
            />
            {texts('map_button')}
          </Grid>
        </SelectButton>
      </Grid>
      {
        searchParams['list-type'] === LocationListType.LIST &&
          <Grid 
            sx={{ 
              [theme.breakpoints.up('md')]: {
                width: '29rem',
                marginTop: '1rem'
              },
              [theme.breakpoints.down('md')]: {
                width: '100%',
                marginTop: '1rem'
              }
            }}
          >
            <SearchInput
              placeholder={texts('search_input_placeholder')}
              initValue={searchParams.search ? decodeURI(searchParams.search) : undefined}
              onSearch={(search: string) => {
                queryClient.resetQueries([queryNames.getLocationList, search ? encodeURI(search) : undefined])
                setSearchParams({ 
                  search: search ? encodeURI(search) : undefined, 
                  page: 1
                }, 'replaceIn')
              }}
              onClear={() => {
                queryClient.resetQueries([queryNames.getLocationList, undefined])
                setSearchParams({ 
                  search: undefined, 
                  page: 1
                }, 'replaceIn')
              }}
            />
          </Grid>
      }
      
    </Grid>
  )
}