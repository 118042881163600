import { Grid, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { OauthData, ProviderType } from '../../../../types/common'
import LogoIconKolektywBlackImage from '../../../../assets/images/logoIconKolektywBlack.svg'
import { Form, Formik } from 'formik'
import { RegistrationFormData } from '../../../../types/form/auth'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '../../../customMui/TextField'
import PasswordField from '../../../customMui/PasswordField'
import Checkbox from '../../../customMui/Checkbox'
import { registrationFormDataValidation } from '../../../../validations/auth'
import Link from '../../../customMui/Link'
import { useRegister } from '../../../../hooks/auth/useRegister'
import { mapAppleRegistrationFormData, mapFacebookRegistrationFormData, mapGoogleRegistrationFormData, mapRegistrationFormData } from '../../../../mapping/auth'
import { useAppleRegister } from '../../../../hooks/auth/useAppleRegister'
import { useFacebookRegister } from '../../../../hooks/auth/useFacebookRegister'
import { useGoogleRegister } from '../../../../hooks/auth/useGoogleRegister'

type Props = {
  accountType: ProviderType
  oauthData?: OauthData
  email?: string
  onSuccess: () => void
}

export default function IntroduceYourself(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('introduceYourself').t

  const registerMutation = useRegister()
  const appleRegisterMutation = useAppleRegister()
  const facebookRegisterMutation = useFacebookRegister()
  const googleRegisterMutation = useGoogleRegister()

  const handleSubmit = (data: RegistrationFormData) => {
    switch (data.accountType) {
      case ProviderType.PASSWORD:
        registerMutation.mutate(mapRegistrationFormData(data), {
          onSuccess: props.onSuccess
        })
        break
      case ProviderType.APPLE:
        appleRegisterMutation.mutate(
          mapAppleRegistrationFormData(
            data, 
            props.oauthData!.token, 
            props.oauthData!.nonce!
          ), 
          {
            onSuccess: props.onSuccess
          }
        )
        break
      case ProviderType.FACEBOOK:
        facebookRegisterMutation.mutate(
          mapFacebookRegistrationFormData(
            data, 
            props.oauthData!.token
          ), 
          {
            onSuccess: props.onSuccess
          }
        )
        break
      case ProviderType.GOOGLE:
        googleRegisterMutation.mutate(
          mapGoogleRegistrationFormData(
            data, 
            props.oauthData!.token
          ), 
          {
            onSuccess: props.onSuccess
          }
        )
        break
    }
  }

  const isLoading = registerMutation.isLoading || 
    appleRegisterMutation.isLoading ||
    facebookRegisterMutation.isLoading ||
    googleRegisterMutation.isLoading

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
      gap='1rem'
      textAlign='center'
      paddingX='2rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '4rem',
          width: '30rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '4rem',
          paddingBottom: '2rem',
          width: '30rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '2rem',
          width: '100vw'
        }
      }}
    >
      <Box
        component='img'
        src={LogoIconKolektywBlackImage}
        sx={{
          [theme.breakpoints.up('md')]: {
            height: '5.25rem'
          },
          [theme.breakpoints.down('md')]: {
            height: '4rem'
          },
          [theme.breakpoints.down('sm')]: {
            height: '4rem'
          }
        }}
      />
      <Typography variant='h1'>
        {texts('title')}
        <Box 
          component='span'
          color={theme.palette.secondary.main}
        >
          {texts('title_name')}
        </Box>
      </Typography>
      <Typography 
        variant='subtitle1'
        marginBottom='1rem'
      >
        {texts('subtitle')}
      </Typography>
      <Grid width='100%'>
        <Formik<RegistrationFormData>
          initialValues={{
            accountType: props.accountType,
            firstName: '',
            lastName: '',
            nick: '',
            phone: '',
            password: '',
            email: props.email ?? '',
            privacyPolicy: false,
            newsletter: false
          }}
          onSubmit={handleSubmit}
          validationSchema={registrationFormDataValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <Grid 
                container
                columnGap='1rem'
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    flexWrap: 'nowrap'
                  },
                  [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap'
                  }
                }}
              >
                <Grid item sm={6} xs={12}>
                  <TextField
                    name='firstName'
                    label={texts('first_name_field_label')}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    name='lastName'
                    label={texts('last_name_field_label')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <TextField
                name='nick'
                label={texts('nick_field_label')}
                fullWidth
              />
              <TextField
                name='phone'
                label={texts('phone_field_label')}
                fullWidth
              />
              {
                props.accountType !== ProviderType.PASSWORD
                &&  <TextField
                      name='email'
                      label={texts('email_field_label')}
                      fullWidth
                    />
              }
              {
                props.accountType === ProviderType.PASSWORD
                &&  <PasswordField
                      name='password'
                      label={texts('password_field_label')}
                    />
              }
              <Grid 
                container
                flexDirection='column'
                width='100%' 
                textAlign='start'
              >
                <Checkbox
                  name='privacyPolicy'
                  label={
                    <Typography variant='body2'>
                      {texts('privacy_policy_label')}
                      <Link to='https://kolektyw.fit/polityka-prywatnosci'>
                        {texts('privacy_policy_url_label')}
                      </Link>
                    </Typography>
                  }
                />
                <Checkbox
                  name='newsletter'
                  label={
                    <Typography variant='body2'>
                      {texts('newsletter_label')}
                    </Typography>
                 }
                />
              </Grid>
              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                loading={isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
              >
                {texts('submit_button_label')}
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}