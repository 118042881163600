import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import PasswordField from '../../customMui/PasswordField'
import { ChangePwdFormData } from '../../../types/form/account'
import { changePwdFormDataValidation } from '../../../validations/profile'
import { useChangePassword } from '../../../hooks/data/account/useChangePassword'
import { mapChangePwdFormData } from '../../../mapping/account'

export default function ChangePwdCardView() {
  const theme = useTheme()
  const texts = useTranslation('profileSettings').t

  const mutation = useChangePassword()

  const handleSubmit = (data: ChangePwdFormData, formikHelpers: FormikHelpers<ChangePwdFormData>) => {
    mutation.mutate(mapChangePwdFormData(data), {
      onSettled: () => {
        formikHelpers.resetForm()
      }
    })
  }

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent
        sx={{
          [theme.breakpoints.up('md')]: {
            padding: '2rem',
            ':last-child': {
              padding: '2rem'
            }
          },
          [theme.breakpoints.down('md')]: {
            padding: '1rem',
            ':last-child': {
              padding: '1rem'
            }
          }
        }}
      >
        <Typography variant='h4' color='#BBBBBB'>
          {texts('change_pwd_section_title')}
        </Typography>
        <Grid marginTop='1.5rem'>
          <Formik<ChangePwdFormData>
            initialValues={{
              currentPassword: '',
              newPassword: ''
            }}
            onSubmit={handleSubmit}
            validationSchema={changePwdFormDataValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <Grid
                  container
                  flexDirection='column'
                  alignItems='center'
                >
                  <Grid
                    sx={{
                      [theme.breakpoints.up('sm')]: {
                        width: '80%'
                      },
                      [theme.breakpoints.down('sm')]: {
                        width: '100%'
                      }
                    }}
                  >
                    <PasswordField
                      name='currentPassword'
                      label={texts('old_password_field_label')}
                    />
                    <PasswordField
                      name='newPassword'
                      label={texts('new_password_field_label')}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  marginTop='1rem'
                  justifyContent='flex-end'
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    size='medium'
                    loading={mutation.isLoading}
                    disabled={!formikProps.dirty || !formikProps.isValid}
                    sx={{
                      width: '15rem',
                      height: '2rem !important',
                      [theme.breakpoints.up('md')]: {
                        marginBottom: '0',
                        marginRight: '0'
                      },
                      [theme.breakpoints.down('md')]: {
                        marginBottom: '1rem',
                        marginRight: '1rem'
                      },
                      [theme.breakpoints.down('sm')]: {
                        marginBottom: '1rem',
                        marginRight: '0'
                      }
                    }}
                  >
                    {texts('submit_button_label')}
                  </LoadingButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </CardContent>
    </Card>
  )
}