import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import NotFoundImage from '../../assets/images/404.png'
import ErrorPage from './ErrorPage'
import { useTheme } from '@mui/system'

export default function NotFoundError() {
  const theme = useTheme()
  const texts = useTranslation('errors').t
  const navigate = useNavigate()

  return (
    <ErrorPage
      image={NotFoundImage}
      title={texts('page_not_found_title')}
      description={texts('page_not_found_subtitle')}
      button={{
        label: texts('page_not_found_button_label'),
        onClick: () => navigate('/', { replace: true })
      }}
      sx={{
        container: {
          [theme.breakpoints.up('md')]: {
            height: '100%',
            justifyContent: 'center',
            paddingInline: '1rem'
          },
          [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 6rem)',
            justifyContent: 'center',
            paddingInline: '1rem'
          }
        },
        img: {
          [theme.breakpoints.up('md')]: {
            marginBottom: '2rem',
            width: '18.5rem',
            height: '15rem'
          },
          [theme.breakpoints.down('md')]: {
            marginBottom: '1.5rem',
            width: '13.875rem',
            height: '11.25rem'
          },
          [theme.breakpoints.down('sm')]: {
            marginBottom: '1.5rem',
            width: '9.25rem',
            height: '7.5rem'
          }
        }
      }}
    />
  )
}
