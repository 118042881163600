import { Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BaseDialog from '../../customMui/BaseDialog'
import PackageCardView from './PackageCardView'
import useGetProductList from '../../../hooks/data/product/useGetProductList'
import Loader from '../../customMui/Loader'

type Props = {
  open: boolean
  demo: boolean
  onClose: () => void
}

export default function BuyPackageDialog(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('buyPackageDialog').t

  const { data, isFetching, isError } = useGetProductList({
    limit: parseInt(process.env.REACT_APP_PRODUCT_LIST_LIMIT!),
    offset: 0
  })

  return (
    <BaseDialog
      open={props.open}
      closeButton
      title={texts(props.demo ? 'used_demo_title' : 'title')}
      onCloseButtonClick={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          maxHeight: '90%',
          [theme.breakpoints.up('md')]: {
            minWidth: '37.5rem'
          },
          [theme.breakpoints.down('md')]: {
            minWidth: '32.5rem'
          },
          [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '100%',
            margin: '1rem'
          },
          '::-webkit-scrollbar': {
            width: '0.4rem',
          },
          '::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            borderRadius: '0.2rem'
          }
        }
      }}
    >
      <Typography
        sx={{
          marginTop: '1rem'
        }}
      >
        {texts(props.demo ? 'used_demo_description' : 'description')}
      </Typography>
      <Grid marginTop='1.5rem'>
        <Typography
          variant='h4'
          color='#BBBBBB'
        >
          {texts('available_packages_title')}
        </Typography>
        {
          (isFetching || isError) &&
            <Grid
              width='100%'
              marginTop='2rem'
              container
              justifyContent='center'
            >
              <Loader />
            </Grid> 
        }
        {
          data != null &&
            <Grid
              display='grid'
              marginTop='1.5rem'
              sx={{
                gridAutoRows: 'auto',
                gap: '.5rem',
                [theme.breakpoints.up('md')]: {
                  gridTemplateColumns: '1fr 1fr'
                },
                [theme.breakpoints.down('md')]: {
                  gridTemplateColumns: '1fr 1fr'
                },
                [theme.breakpoints.down('sm')]: {
                  gridTemplateColumns: '1fr'
                }
              }}
            >
              {
                data.products.map(p => (
                  <PackageCardView
                    package={p}
                  />
                ))
              }
            </Grid>
        }
      </Grid>
      
    </BaseDialog>
  )
}
