import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export function useUnlinkTrainee() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (uuid: string) => {
    const { data } = await dataApi.unlinkTrainee(uuid)
    return data
  }, {
    onSuccess: (_, uuid) => {
      queryClient.resetQueries([queryNames.getTraineeList])
      queryClient.removeQueries([queryNames.getTraineeDetails, uuid])
      
      toast.success(texts('successes:trainee_disconnected'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}