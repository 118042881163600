import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Account } from '../../../api/data/types'
import { mapAccountDto } from '../../../mapping/account'
import { mapFullName } from '../../../mapping/common'
import { mapAccountDto as mapAccountDtoForStorage } from '../../../mapping/storage'
import { updateUserData, updateUserName } from '../../../redux/storageToolkit'
import store from '../../../redux/store'
import { Account as AccountType } from '../../../types/account'
import { HttpError } from '../../../types/common'
import { SessionData } from '../../../types/session'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { AccountDto } from '../../../api/types'

const dataApi = DataApi.getInstance()

export function useEditAccountData() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (body: Account.EditAccountDataDto) => {
    const { data } = await dataApi.editAccountData(body)
    return data
  }, {
    onSuccess: (data) => {
      if (!(data.data as Account.ConfirmationRequiredDto).confirmationRequired) {
        store.dispatch(updateUserName(mapFullName((data.data as AccountDto).firstName, (data.data as AccountDto).lastName)))
        queryClient.setQueryData([queryNames.getAccountData], (oldData?: AccountType) => {
          if (!oldData) {
            return oldData
          }
          return mapAccountDto(data.data! as AccountDto)
        })
        queryClient.setQueryData([queryNames.getSessionData], (oldData?: SessionData) => {
          if (!oldData) {
            return oldData
          }
          return {
            ...oldData,
            account: mapAccountDto(data.data! as AccountDto)
          }
        })
        store.dispatch(updateUserData(mapAccountDtoForStorage(data.data! as AccountDto)))
        toast.success(texts('successes:account_data_updated'))
      }
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}