import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import AuthApi from '../../api/auth/AuthApi'
import { EmailDto } from '../../api/auth/types'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'

const authApi = AuthApi.getInstance()

export function useEmailCheck() {
  const texts = useTranslation().t

  const mutation = useMutation(async (body: EmailDto) => {
    const { data } = await authApi.emailCheck(body)
    return data
  }, {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}