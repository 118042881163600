import { Clear } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { timeOfDaySelectOptions } from '../../../types/calendar'
import { TrainingRoomOption } from '../../../types/filters'
import { CalendarFilterFormData } from '../../../types/form/filter'
import FormikMultiAutocomplete from '../../customMui/FormikMultiAutocomplete'
import FormikMultiSelectField from '../../customMui/FormikMultiSelectField'

type Props = {
  initValues: CalendarFilterFormData
  onSubmit: (data: CalendarFilterFormData) => void
  rooms: TrainingRoomOption[]
  fixedRoom: boolean
}

export default function FilterForm(props: Props) {
  const texts = useTranslation('locationCalendar').t
  
  return (
    <Formik<CalendarFilterFormData>
      initialValues={props.initValues}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => (
        <Form>
          <Grid paddingX='.5rem'>
            <Typography variant='h4' marginBottom='2rem'>
              {texts('filter_popover_title')}
            </Typography>
            {
              !props.fixedRoom &&
                <FormikMultiAutocomplete<TrainingRoomOption>
                  name='rooms'
                  label={texts('filter_rooms_field_label')}
                  placeholder={texts('filter_all_placeholder')}
                  shrink
                  limitTags={3}
                  options={props.rooms}
                />
            }
            <FormikMultiSelectField
              name='timesOfDay'
              label={texts('filter_times_of_day_field_label')}
              options={timeOfDaySelectOptions}
              placeholder={texts('filter_all_placeholder')}
              shrink
            />
          </Grid>
          <Grid marginBottom='1rem' marginTop='-1rem'>
            <Button
              variant='text'
              onClick={() => {
                formikProps.setFieldValue('rooms', [])
                formikProps.setFieldValue('timesOfDay', [])
              }}
            >
              <Grid
                container
                alignItems='center'
                gap='.5rem'
              >
                <Clear sx={{ marginTop: '-.125rem' }}/>
                {texts('filter_clear_button_label')}
              </Grid>
            </Button>
          </Grid>
          <Grid
            container
            justifyContent='flex-end'
          >
            <Button
              type='submit'
              variant='contained'
              sx={{ width: '10rem' }}
            >
              {texts('filter_confirm_button_label')}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}