import { Card, CardContent, Grid, useTheme, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReservationStatus } from '../../../types/reservations'
import { Reservation } from '../../../types/reservations'
import ReservationCardViewActions from './ReservationCardViewActions'
import moment from 'moment'
import OutlinedBadge from '../../common/OutlinedBadge'
import { AccountCircleOutlined } from '@mui/icons-material'
import { useState } from 'react'
import CancelReservationDialog from '../../dialogs/CancelReservationDialog'
import { useNavigate } from 'react-router-dom'
import { mapReservationStatusToColor } from '../../mapping/reservations'

type Props = {
  reservation: Reservation
}

export default function ReservationCardView(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('reservationList').t
  const navigate = useNavigate()
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)

  return (
    <>
      <CancelReservationDialog
        open={isCancelDialogOpen}
        uuid={props.reservation.uuid}
        date={props.reservation.event.startDate.format('DD.MM.YYYY')}
        time={props.reservation.event.formattedStartTime}
        onClose={() => { setIsCancelDialogOpen(false) }}
      />
      <Card sx={{ position: 'relative', maxWidth: '100%' }}>
        <CardContent
          sx={{
            cursor: 'pointer'
          }}
          onClick={() => {
            navigate(`/reservations/${props.reservation.uuid}/details`)
          }}
        >
          <Grid
            container
            gap='1rem'
            flexWrap='nowrap'
          >
            <Grid
              container
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              gap='.25rem'
              flexShrink={0}
              sx={{
                backgroundColor: props.reservation.status === ReservationStatus.ACTIVE ? theme.palette.secondary.main : '#BBBBBB',
                color: '#fff',
                [theme.breakpoints.up('md')]: {
                  padding: '.5rem',
                  width: '6.25rem'
                },
                [theme.breakpoints.down('md')]: {
                  padding: '.5rem',
                  width: '6.25rem'
                },
                [theme.breakpoints.down('sm')]: {
                  padding: '.25rem',
                  width: '5.25rem'
                }
              }}
            >
              <Typography
                variant='body1'
                fontWeight={700}
              >
                {texts(
                  'tile_date_label',
                  {
                    diff: moment(props.reservation.event.startDate).startOf('d').diff(moment().startOf('d'), 'd'),
                    date: props.reservation.event.startDate.format('DD.MM.YYYY')
                  }
                )}
              </Typography>
              <Typography
                variant='body1'
                fontWeight={700}
              >
                {props.reservation.event.formattedStartTime}
              </Typography>
            </Grid>
            <Grid
              container
              flexDirection='column'
              justifyContent='center'
              gap='.5rem'
            >
              <Grid 
                container
                gap='.5rem'
                sx={{
                  [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingRight: '2rem'
                  },
                  [theme.breakpoints.down('md')]: {
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-start',
                    paddingRight: 0
                  },
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-start',
                    paddingRight: 0
                  }
                }}
              >
                <Grid 
                  container
                  maxWidth='max-content'
                  flexGrow={0}
                  alignItems='center'
                  gap='.5rem'
                  sx={{
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'row',
                      alignItems: 'center'
                    },
                    [theme.breakpoints.down('sm')]: {
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }
                  }}
                >
                  <Typography
                    variant='body1'
                    fontWeight={700}
                  >
                    {texts(
                      'location_label',
                      {
                        location: props.reservation.place.name,
                        room: props.reservation.trainingRoom.name
                      }
                    )}
                  </Typography>
                  <Typography variant='body1'>
                    {texts(
                      'address_label',
                      {
                        streetAddress: props.reservation.place.address.streetAddress,
                        postalCode: props.reservation.place.address.postalCode,
                        town: props.reservation.place.address.town
                      }
                    )}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    height: '1.375rem'
                  }}
                >
                  {
                    props.reservation.status !== ReservationStatus.ACTIVE &&
                      <OutlinedBadge 
                        color={mapReservationStatusToColor(props.reservation.status)}
                        label={texts('reservation_status_label', { status: props.reservation.status })}
                      />
                  }                
                </Grid>
              </Grid>
              <Grid 
                container
                height='1.25rem'
                alignItems='center'
                gap='.25rem'
              >
                {
                  props.reservation.trainee && 
                    <>
                      <AccountCircleOutlined 
                        sx={{ 
                          [theme.breakpoints.up('md')]: {
                            marginTop: '-.125rem',
                            fontSize: '1.25rem'
                          },
                          [theme.breakpoints.down('md')]: {
                            marginTop: '-.125rem',
                            fontSize: '1.25rem'
                          },
                          [theme.breakpoints.down('sm')]: {
                            marginTop: '-.25rem',
                            fontSize: '1rem'
                          }
                        }} 
                      />
                      <Typography variant='body1'>
                        {props.reservation.trainee.fullName}
                      </Typography>
                    </>
                }
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <ReservationCardViewActions 
          reservation={props.reservation}
          onCancel={() => setIsCancelDialogOpen(true)}
        />
      </Card>
    </>
  )
}