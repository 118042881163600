import { Autocomplete as MuiAutocomplete, TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AutocompleteBaseOption } from '../../types/common'

type Props<T> = {
  label: String
  placeholder?: string
  shrink?: boolean
  options: T[]
  loading?: boolean
  defaultValue?: T
  onChange: (val: T) => void
  onInputChange: (e: React.SyntheticEvent<Element, Event>, value: string) => void
}

export default function Autocomplete<T extends AutocompleteBaseOption = AutocompleteBaseOption>(props: Props<T>) {
  const texts = useTranslation('common').t

  return (
    <MuiAutocomplete<T, false, true, false> 
      loading={props.loading}
      defaultValue={props.defaultValue}
      loadingText={texts('loading_placeholder')}
      noOptionsText={texts('no_options_placeholder')}
      options={props.options}
      filterOptions={(options) => options}
      onChange={(_, val: T) => {
        props.onChange(val)
      }}
      fullWidth
      disableClearable
      multiple={false}
      onInputChange={props.onInputChange}
      renderInput={(params: TextFieldProps) => (
        <MuiTextField
          {...params}
          label={props.label}
        />
      )}
      getOptionLabel={(option: T | string) => {
        return typeof option === 'string'
          ? option
          : option.label
      }}
    />
  )
}