import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { Button, Grid, IconButton, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TrainingRoomOption } from '../../../types/filters'
import { CalendarFilterFormData } from '../../../types/form/filter'
import FilterSection from './FilterSection'

export enum DateRangeChangeType {
  BACK,
  RESET,
  FORWARD
}

type Props = {
  initValues?: CalendarFilterFormData
  disabledButtons: DateRangeChangeType[]
  onDateRangeChange: (type: DateRangeChangeType) => void
  onFilterChange: (data: CalendarFilterFormData) => void
  rooms: TrainingRoomOption[]
  isFilterPresent: boolean
  disableRoomFilter: boolean
}

export default function ControlSection(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('locationCalendar').t

  return (
    <Grid 
      container
      marginBottom='1rem'
      width='100%'
      sx={{
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row'
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column-reverse',
          alignItems: 'center'
        }
      }}
    >
      <Grid sm={3} xs={0}/>
      <Grid
        container
        width='max-content'
        justifyContent='center'
        alignItems='center'
        gap='1rem'
        flexWrap='nowrap'
        sm={6}
        xs={12}
      >
        <IconButton
          sx={{
            color: '#000000',
            width: '1rem',
            height: '1rem'
          }}
          onClick={() => {
            props.onDateRangeChange(DateRangeChangeType.BACK)
          }}
          disabled={props.disabledButtons.includes(DateRangeChangeType.BACK)}
        >
          <ArrowLeft 
            fontSize='large'
            sx={{
              marginTop: '-.125rem'
            }}
          />
        </IconButton>
        <Button
          variant='text'
          sx={{
            color: '#000000'
          }}
          onClick={() => {
            props.onDateRangeChange(DateRangeChangeType.RESET)
          }}
        >
          <Typography
            variant='body1'
            fontWeight={500}
          >
            {texts('set_today')}
          </Typography>
        </Button>
        <IconButton
          sx={{
            color: '#000000',
            width: '1rem',
            height: '1rem'
          }}
          onClick={() => {
            props.onDateRangeChange(DateRangeChangeType.FORWARD)
          }}
        >
          <ArrowRight 
            fontSize='large'
            sx={{
              marginTop: '-.125rem'
            }}
          />
        </IconButton>
      </Grid>
      <Grid 
        sm={3}
        xs={12}
        container 
        justifyContent='flex-end'
      >
        <FilterSection 
          initValues={props.initValues}
          disabledRooms={props.disableRoomFilter}
          onFilterChange={props.onFilterChange}
          rooms={props.rooms}
          isFilterPresent={props.isFilterPresent}
        />
      </Grid>
    </Grid>
  )
}