import { Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import {
  Chart as ChartJS,
  Filler,
  LineElement,
  PointElement,
  RadialLinearScale
} from 'chart.js'
import { useState } from 'react'
import { Radar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import OpenQuestionnaireIcon from '../../icons/OpenQuestionnaireIcon'
import QuestionnaireAnswersDialog from '../../dialogs/QuestionnaireAnswersDialog'
import TrainingIcon from '../../icons/TrainingIcon'
import { Questionnaire } from '../../../types/trainees'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
)

type Props = {
  questionnaire: Questionnaire | null
}

export default function AISuggestionSection(props: Props) {
  const texts = useTranslation('traineeDetails').t
  const theme = useTheme()
  const { isDesktop } = useGetDeviceType()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  
  return (
    <>
      {
        props.questionnaire != null && 
          <QuestionnaireAnswersDialog
            open={isDialogOpen}
            questionnaire={props.questionnaire}
            onClose={() => { setIsDialogOpen(false) }}
          />
      }
      <Grid
        sx={{
          [theme.breakpoints.up('md')]: {
            marginTop: '.6rem'
          },
          [theme.breakpoints.down('md')]: {
            marginTop: 0
          }
        }}
      >
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h3'>
            {texts('ai_suggestion_title')}
          </Typography>
        </Grid>
        <Card
          sx={{
            [theme.breakpoints.up('md')]: {
              marginTop: '1rem',
            },
            [theme.breakpoints.down('md')]: {
              marginTop: '.75rem',
            },
            [theme.breakpoints.down('sm')]: {
              marginTop: '.5rem',
            }
          }}
        >
          <CardContent>
            <Grid>
              <Typography
                variant={isDesktop ? 'h5' : 'h4'}
                color='#BBBBBB'
              >
                {texts('training_method_section_title')}
              </Typography>
              <Grid
                container
                alignItems='center'
                sx={{
                  [theme.breakpoints.up('md')]: {
                    marginTop: '1rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    marginTop: '1rem',
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginTop: '.75rem',
                  }
                }}
              >
                <TrainingIcon 
                  sx={{ 
                    fontSize: '1.25rem',
                    marginRight: '.5rem',
                    marginTop: '-.25rem'
                  }}
                />
                <Typography
                  variant='body1'
                  fontWeight={700}
                >
                  {
                    props.questionnaire != null
                    ? texts('suggested_training_method', { method: props.questionnaire.suggestedTrainingMethod })
                    : '-'
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid marginTop='1.5rem'>
              <Typography
                variant={isDesktop ? 'h5' : 'h4'}
                color='#BBBBBB'
              >
                {texts('ai_chart_section_title')}
              </Typography>
              <Grid
                container
                justifyContent='center'
                width='100%'
                sx={{
                  [theme.breakpoints.up('md')]: {
                    marginTop: '.5rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    marginTop: '.5rem',
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginTop: '.75rem',
                  }
                }}
              >
                {
                  props.questionnaire != null
                  ? <Grid
                      sx={{
                        [theme.breakpoints.up('md')]: {
                          height: '12.5rem',
                          width: '17.5rem'
                        },
                        [theme.breakpoints.down('md')]: {
                          height: '13.75rem',
                          width: '22.5rem'
                        },
                        [theme.breakpoints.down('sm')]: {
                          height: '10rem',
                          width: '16.25rem'
                        }
                      }}
                    >
                      <Radar
                        height='100%'
                        width='100%'
                        options={{
                          maintainAspectRatio: false,
                          responsive: true,
                          scales: {
                            r: {
                              max: isDesktop ? 5 : 6,
                              min: 0,
                              ticks: {
                                stepSize: isDesktop ? 1 : 2
                              },
                              grid: {
                                circular: true
                              }
                            }
                          }
                        }}
                        data={{
                          labels: texts('training_goal_labels'),
                          datasets: [
                            {
                              data: [
                                props.questionnaire.trainingGoals.strength,
                                props.questionnaire.trainingGoals.stamina,
                                props.questionnaire.trainingGoals.generalFitness,
                                props.questionnaire.trainingGoals.flexibility,
                                props.questionnaire.trainingGoals.speed,
                              ],
                              backgroundColor:`${theme.palette.secondary.main}66`,
                              borderWidth: 2,
                              pointRadius: 0,
                              borderColor: theme.palette.secondary.main,
                            }
                          ]
                        }}
                      />
                    </Grid>
                  : <Grid marginY='1rem'>
                      <Typography variant='body1' color='#BBBBBB'>
                        {texts('no_data')}
                      </Typography>
                    </Grid>
                }
              </Grid>
            </Grid>
            {
              props.questionnaire != null &&
                <Grid
                  container
                  justifyContent='flex-end'
                  marginTop='.5rem'
                >
                  <Button
                    variant='text'
                    onClick={() => {
                      setIsDialogOpen(true)
                    }}
                  >
                    <Grid
                      container
                      alignItems='center'
                    >
                      <OpenQuestionnaireIcon 
                        sx={{ 
                          fontSize: '1.25rem',
                          marginRight: '.25rem'
                        }}
                      />
                      {texts('questionnaire_results_button_label')}
                    </Grid>
                  </Button>
                </Grid>
            }
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}
