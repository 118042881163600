import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Stripe } from '../../../api/data/types'
import { mapConfigDto } from '../../../mapping/payments'
import { HttpError } from '../../../types/common'
import { StripeConfig } from '../../../types/payments'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export function useEditStripeConfig() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (body: Stripe.ConfigDto) => {
    const { data } = await dataApi.editStripeConfig(body)
    return mapConfigDto(data.data!)
  }, {
    onSuccess: (data: StripeConfig) => {
      queryClient.setQueryData([queryNames.getStripeConfig], () => {
        return data
      })
      queryClient.invalidateQueries([queryNames.getTrainerProductList])
      toast.success(texts('successes:stripe_config_updated'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}