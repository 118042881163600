import { Trans, useTranslation } from 'react-i18next'
import { useUnlinkTrainee } from '../../hooks/data/trainees/useUnlinkTrainee'
import Dialog from '../customMui/Dialog'

type Props = {
  open: boolean
  uuid: string
  name: string
  onClose: () => void
  onSuccess?: () => void
}

export default function UnlinkTraineeDialog(props: Props) {
  const texts = useTranslation('unlinkTraineeDialog').t

  const mutation = useUnlinkTrainee()

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey='unlinkTraineeDialog:title'/>}
      description={
        <Trans 
          i18nKey='unlinkTraineeDialog:description'
          values={{
            name: props.name,
          }}
        />
      }
      warning={<Trans i18nKey='unlinkTraineeDialog:warning'/>}
      closeButtonLabel={texts('cancel')}
      confirmButtonLabel={texts('confirm')}
      loading={mutation.isLoading}
      onCloseButtonClick={props.onClose}
      onConfirmButtonClick={() => {
        mutation.mutate(props.uuid, {
          onSettled: props.onClose,
          onSuccess: props.onSuccess
        })
      }}
    />
  )
}
