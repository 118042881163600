import { Grid, useTheme } from '@mui/material'
import * as React from 'react'
import PinField from 'react-pin-field'

type Props = {
  onChange: (code: string) => void
}

export default React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement[]>) => {
  const theme = useTheme()

  return (
    <Grid 
      container
      flexDirection='row'
      width='100%'
      gap={{ xs: '.25rem', sm: '.375rem' }}
      justifyContent='center'
      sx={{
        'input': {
          borderWidth: '.0625rem',
          borderStyle: 'solid',
          borderRadius: '.5rem',
          outlineColor: theme.palette.secondary.main,
          borderColor: '#bbbbbb',
          textAlign: 'center',
          fontFamily: 'Roboto',
          color: '#444444',
          [theme.breakpoints.up('md')]: {
            width: '2.875rem',
            height: '3.5rem',
            fontSize: '1rem',
            '&:nth-of-type(3)': {
              marginRight: '.25rem'
            },
            '&:nth-of-type(4)': {
              marginLeft: '.25rem',
            }
          },
          [theme.breakpoints.down('md')]: {
            width: '2.875rem',
            height: '3.5rem',
            fontSize: '1rem',
            '&:nth-of-type(3)': {
              marginRight: '.25rem'
            },
            '&:nth-of-type(4)': {
              marginLeft: '.25rem',
            }
          },
          [theme.breakpoints.down('sm')]: {
            width: '2.25rem',
            height: '3rem',
            fontSize: '.875rem',
            '&:nth-of-type(3)': {
              marginRight: '.25rem'
            },
            '&:nth-of-type(4)': {
              marginLeft: '.25rem',
            }
          }
        }
      }}
    > 
      <PinField
        validate={/[0-9]/}
        pattern='[0-9]*'
        inputMode='numeric'
        length={6}
        ref={ref}
        onChange={props.onChange}
      />
    </Grid>
  )
})