import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'

const dataApi = DataApi.getInstance()

export function useGetStripeBillingSession() {
  const texts = useTranslation().t

  const mutation = useMutation(async (returnUrl: string) => {
    const { data } = await dataApi.getStripeBillingSession(returnUrl)
    return data
  }, {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}