import { Button, Grid, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ScheduleDay } from '../../../types/trainees'
import ReservationTile from './ReservationTile'
import { useNavigate } from 'react-router-dom'
import EmptyListView from '../../common/EmptyListView'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'

type Props = {
  scheduleDays: ScheduleDay[]
}

export default function ScheduleSection(props: Props) {
  const navigate = useNavigate()
  const texts = useTranslation('traineeDetails').t
  const theme = useTheme()
  const { isDesktop } = useGetDeviceType()
  return (
    <Grid>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        flexWrap='nowrap'
      >
        <Typography variant='h3'>
          {texts('schedule_section_title')}
        </Typography>
        <Button
          disableElevation
          variant='contained'
          onClick={() => {
            navigate('/locations')
          }}
        >
          {texts('reserve_button_label')}
        </Button>
      </Grid>
      <Grid
        container
        flexDirection='column'
        gap='1.5rem'
        sx={{
          [theme.breakpoints.up('sm')]: {
            marginTop: '.75rem'
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: '.5rem'
          }
        }}
      >
        {
          props.scheduleDays.length === 0 &&
            <Grid marginTop='3rem'>
              <EmptyListView
                description={texts('no_data_description')}
                title={texts('no_data_title')}
              />
            </Grid>
        }
        {
          props.scheduleDays.map(d => (
            <Grid key={`schedule-date-${d.date}`}>
              <Typography 
                variant={isDesktop ? 'subtitle1' : 'h4'}
                color='#BBBBBB'
              >
                {texts(
                  'tile_date_label',
                  {
                    diff: moment(d.date).diff(moment().startOf('d'), 'd'),
                    date: moment(d.date).format('DD.MM.YYYY')
                  }
                )}
              </Typography>
              <Grid
                container
                flexDirection='column'
                gap='.75rem'
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    marginTop: '1rem'
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginTop: '.5rem'
                  }
                }}
              >
                {
                  d.reservations.map(r => (
                    <ReservationTile key={r.uuid} reservation={r} />
                  ))
                }
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  )
}
