import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import Loader from '../../components/customMui/Loader'
import MainSection from '../../components/trainees/invite/MainSection'
import TopSection from '../../components/trainees/invite/TopSection'
import useGetConnectionCode from '../../hooks/data/account/useGetConnectionCode'
import { queryNames } from '../../hooks/queries'
import ConnectionError from '../Errors/ConnectionError'

export default function InviteTrainee() {
  const queryClient = useQueryClient()

  const connectionCode = useGetConnectionCode()

  if (connectionCode.isFetching) {
    return (
      <Grid marginTop='10rem' width='100%' overflow='hidden'>
        <Loader />
      </Grid>
    )
  }

  if (connectionCode.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getConnectionCode])
        }}
      />
    )
  }

  return (
    <Grid width='100%' paddingX='1rem'>
      <TopSection />
      <MainSection 
        connectionCode={connectionCode.data!.connectionCode}
      />
    </Grid>
  )
}