import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, Grid, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { EditTraineeNoteFormData } from '../../types/form/trainees'
import BaseDialog from '../customMui/BaseDialog'
import TextField from '../customMui/TextField'
import { editTraineeNoteFormDataValidation } from '../../validations/trainee'
import { useEditTraineeNote } from '../../hooks/data/trainees/useEditTraineeNote'

type Props = {
  open: boolean
  uuid: string
  note: string | null
  onClose: () => void
}

export default function EditTraineeNoteDialog(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('editTraineeNoteDialog').t

  const mutation = useEditTraineeNote()

  const handleSubmit = (data: EditTraineeNoteFormData) => {
    mutation.mutate({
      traineeUuid: props.uuid,
      note: data.note || null
    }, {
      onSuccess: () => {
        props.onClose()
      }
    })
  }

  return (
    <BaseDialog
      open={props.open}
      title={texts(props.note ? 'edit_title' : 'create_title')}
      onCloseButtonClick={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          maxHeight: '90%',
          [theme.breakpoints.up('md')]: {
            minWidth: '37.5rem'
          },
          [theme.breakpoints.down('md')]: {
            minWidth: '32.5rem'
          },
          [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '100%',
            margin: '1rem'
          },
          '::-webkit-scrollbar': {
            width: '0.4rem',
          },
          '::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            borderRadius: '0.2rem'
          }
        }
      }}
    >
      <Grid>
        <Formik<EditTraineeNoteFormData>
          initialValues={{
            note: props.note
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={editTraineeNoteFormDataValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <Grid marginTop='1rem'>
                <TextField
                  name='note'
                  fullWidth
                  multiline
                  rows={8}
                  label={texts('note_field_label')}
                />
              </Grid>
              <DialogActions sx={{ marginTop: '1rem' }}>
                <Button
                  variant='outlined'
                  size='medium'
                  onClick={props.onClose}
                  sx={{
                    width: '10rem',
                    height: '2rem'
                  }}
                >
                  {texts('cancel_button_label')}
                </Button>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  type='submit'
                  disabled={!formikProps.dirty || !formikProps.isValid}
                  loading={mutation.isLoading} 
                  sx={{
                    width: '10rem',
                    height: '2rem'
                  }}
                >
                  {texts('submit_button_label')}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Grid>
      
    </BaseDialog>
  )
}
