import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { Place } from '../../../api/data/types'
import { mapSearchDtoList } from '../../../mapping/locations'

const dataApi = DataApi.getInstance()

export default function useGetSelectedReservationPlaces(
  uuids: string[],
  onSuccess: (data: Place.SearchDto[]) => void,
  enabled: boolean
) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getSelectedReservationPlaces, uuids.join(',')], async () => {
    const { data } = await dataApi.getSelectedReservationPlaces(uuids)
    return mapSearchDtoList(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: enabled,
    onSuccess: onSuccess,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
