import { Box, Typography } from '@mui/material'

type Props = {
  color: string
  label: string
}

export default function OutlinedBadge(props: Props) {
  return (
    <Box
      sx={{
        borderColor: props.color,
        color: props.color,
        borderWidth: '.0625rem',
        borderStyle: 'solid',
        width: '100%',
        textAlign: 'center',
        borderRadius: '.75rem',
        overflow: 'hidden',
        paddingBlock: '.125rem',
        paddingInline: '.5rem'
      }}
    >
      <Typography variant='body2'>
        {props.label}
      </Typography>
    </Box>
  )
}

