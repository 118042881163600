import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { mapDetailsDto } from '../../../mapping/trainees'

const dataApi = DataApi.getInstance()

type Options = {
  uuid: string
  onError: (error: AxiosError<HttpError>) => void
}

export default function useGetTraineeDetails(options: Options) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getTraineeDetails, options.uuid], async () => {
    const { data } = await dataApi.getTraineeDetails(options.uuid)
    return mapDetailsDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
      options.onError(error)
    }
  })

  return query
}
