import { SvgIcon, SvgIconProps } from '@mui/material'

export default function CreditCardIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='24px'
      height='18px'
      viewBox='0 0 24 18'
    >
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="PaymentsIcon" transform="translate(-12, -15)" fill="#FFFFFF">
            <g id="credit-card-2" transform="translate(12, 15)">
                <path d="M18.6976744,6.69767442 C15.7707907,6.69767442 13.3953488,9.07311628 13.3953488,12 C13.3953488,14.9268837 15.7707907,17.3023256 18.6976744,17.3023256 C21.6245581,17.3023256 24,14.9268837 24,12 C24,9.07311628 21.6245581,6.69767442 18.6976744,6.69767442 Z M19.5348837,11.1627907 L19.5348837,9.76744186 C19.5348837,9.30530233 19.159814,8.93023256 18.6976744,8.93023256 C18.2355349,8.93023256 17.8604651,9.30530233 17.8604651,9.76744186 L17.8604651,11.1627907 L16.4651163,11.1627907 C16.0029767,11.1627907 15.627907,11.5378605 15.627907,12 C15.627907,12.4621395 16.0029767,12.8372093 16.4651163,12.8372093 L17.8604651,12.8372093 L17.8604651,14.2325581 C17.8604651,14.6946977 18.2355349,15.0697674 18.6976744,15.0697674 C19.159814,15.0697674 19.5348837,14.6946977 19.5348837,14.2325581 L19.5348837,12.8372093 L20.9302326,12.8372093 C21.3923721,12.8372093 21.7674419,12.4621395 21.7674419,12 C21.7674419,11.5378605 21.3923721,11.1627907 20.9302326,11.1627907 L19.5348837,11.1627907 Z" id="Shape"></path>
                <path d="M16.0777674,6.13953488 L0,6.13953488 L0,13.1162791 C0,13.9300465 0.32372093,14.7114419 0.898604651,15.2874419 C1.47460465,15.8623256 2.256,16.1860465 3.06976744,16.1860465 L13.8329302,16.1860465 C12.8651163,15.0619535 12.2790698,13.5985116 12.2790698,12 C12.2790698,9.39013953 13.8396279,7.14306977 16.0777674,6.13953488 Z M7.536,11.1627907 L4.18604651,11.1627907 C3.72390698,11.1627907 3.34883721,11.5378605 3.34883721,12 C3.34883721,12.4621395 3.72390698,12.8372093 4.18604651,12.8372093 L7.536,12.8372093 C7.99813953,12.8372093 8.3732093,12.4621395 8.3732093,12 C8.3732093,11.5378605 7.99813953,11.1627907 7.536,11.1627907 Z M0,4.46511628 L21.7674419,4.46511628 L21.7674419,3.06976744 C21.7674419,2.256 21.4437209,1.47460465 20.8688372,0.898604651 C20.2928372,0.32372093 19.5114419,0 18.6976744,0 C14.832,0 6.93544186,0 3.06976744,0 C2.256,0 1.47460465,0.32372093 0.898604651,0.898604651 C0.32372093,1.47460465 0,2.256 0,3.06976744 L0,4.46511628 L0,4.46511628 Z" id="Shape"></path>
            </g>
        </g>
    </g>
    </SvgIcon>
  )
}