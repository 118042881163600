export const queryNames = {
  getReservationList: 'getReservationList',
  searchLocations: 'searchLocations',
  searchTrainees: 'searchTrainees',
  searchReservationTrainees: 'searchReservationTrainees',
  getSelectedPlaces: 'getSelectedPlaces',
  getSelectedTrainees: 'getSelectedTrainees',
  getSelectedReservationTrainees: 'getSelectedReservationTrainees',
  getReservationDetails: 'getReservationDetails',
  getAccountData: 'getAccountData',
  getEventList: 'getEventList',
  getLocationList: 'getLocationList',
  getMapLocationList: 'getMapLocationList',
  getTraineeList: 'getTraineeList',
  getPlaceDetails: 'getPlaceDetails',
  getTraineeDetails: 'getTraineeDetails',
  getConnectionCode: 'getConnectionCode',
  getSessionData: 'getSessionData',
  searchReservationLocations: 'searchReservationLocations',
  getSelectedReservationPlaces: 'getSelectedReservationPlaces',
  getProductList: 'getProductList',
  getTrainerProductList: 'getTrainerProductList',
  getTrainerProductDetails: 'getTrainerProductDetails',
  getStripeConfig: 'getStripeConfig'
}