import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Reservation } from '../../../api/data/types'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import store from '../../../redux/store'
import { updatePackageData } from '../../../redux/storageToolkit'
import { isDataError } from '../../../services/errors/utils'
import { DataSubcodes } from '../../../services/errors/consts'
import { Event } from '../../../types/events'

const dataApi = DataApi.getInstance()

export function useCreateReservation() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (body: Reservation.CreateDto) => {
    const { data } = await dataApi.createReservation(body)
    return data
  }, {
    onSuccess: (data) => {
      queryClient.resetQueries([queryNames.getReservationList])
      queryClient.resetQueries([queryNames.getEventList])
      store.dispatch(updatePackageData(data.data!))
      toast.success(texts('successes:reservation_created'))
    },
    onError: (error: AxiosError<HttpError>, variables: Reservation.CreateDto) => {
      if (isDataError(error, [DataSubcodes.NO_ACTIVE_TRAINEE_PACKAGE, DataSubcodes.NO_TRAINEE_ENTRIES_LEFT])) {
        return
      }
      showErrorToast(error, texts)
      if (isDataError(error, [DataSubcodes.NO_EMPTY_SEATS, DataSubcodes.EVENT_NOT_FOUND])) {
        const queriesData = queryClient.getQueriesData<Event[]>([queryNames.getEventList])
        for (const queryData of queriesData) {
          queryClient.setQueryData(queryData[0], (oldData?: Event[]) => {
            if (!oldData) {
              return oldData
            }
            const newData = [...oldData]
            newData.splice(oldData.findIndex(e => e.uuid === variables.eventUuid), 1)
            return newData
          })
        }
      }
    }
  })
  return mutation
}