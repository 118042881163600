import { Menu } from '@mui/icons-material'
import { Box, Grid, IconButton, AppBar as MuiAppBar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import LogoKolektywBlackImage from '../../assets/images/logoKolektywBlack.svg'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'
import AccountNav from '../common/AccountNav'

type Props = {
  loggedIn: boolean
  userName: string
  onMenuButtonClick: () => void
}

export default function AppBar(props: Props) {
  const texts = useTranslation(['common', 'imgAlts']).t
  const navigate = useNavigate()
  const { isDesktop } = useGetDeviceType()

  return (
    <MuiAppBar>
      <Grid
        container
        alignItems='center'
        gap='0.75rem'
      >
        <Box
          marginTop='0.1875rem'
          component='img'
          src={LogoKolektywBlackImage}
          height='1.5rem'
          width='8.75rem'
          alt={texts('imgAlts:main_logo')}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(props.loggedIn ? '/' : '/login')
          }}
        />
        <Grid 
          item
          flexGrow={1}
        />
        {
          props.loggedIn && isDesktop && 
            <AccountNav userName={props.userName} />
        }
        {
          props.loggedIn && !isDesktop &&  
            <IconButton onClick={props.onMenuButtonClick}>
              <Menu />
            </IconButton>
        }
      </Grid>
    </MuiAppBar>
  )
}