import { Button, Grid, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SummaryScheduleDay } from '../../types/trainees'
import EmptyListView from '../common/EmptyListView'
import ReservationCardView from './ReservationCardView'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'
import Loader from '../customMui/Loader'

type Props = {
  scheduleDays: SummaryScheduleDay[]
  isLoading: boolean
}

export default function ReservationSection(props: Props) {
  const navigate = useNavigate()
  const texts = useTranslation('summaryPanel').t
  const theme = useTheme()

  const { isDesktop, isMobile } = useGetDeviceType()

  return (
    <Grid>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        flexWrap='nowrap'
      >
        <Typography variant='h3'>
          {texts('reservation_section_title')}
        </Typography>
        <Button
          disableElevation
          variant='contained'
          onClick={() => {
            navigate('/reservations')
          }}
        >
          {texts(isMobile ? 'all_reservations_mobile_button_label' : 'all_reservations_button_label')}
        </Button>
      </Grid>
      <Grid
        container
        flexDirection='column'
        gap='1.5rem'
        sx={{
          [theme.breakpoints.up('sm')]: {
            marginTop: '.75rem'
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: '.5rem'
          }
        }}
      >
        {
          props.isLoading && 
            <Grid marginTop='2rem'>
              <Loader />
            </Grid>
        }
        {
          !props.isLoading && props.scheduleDays.length === 0 &&
            <Grid marginTop='3rem'>
              <EmptyListView
                description={texts('no_reservation_data_description')}
                title={texts('no_reservation_data_title')}
              />
            </Grid>
        }
        {
          !props.isLoading && props.scheduleDays.map(d => (
            <Grid key={`date-${d.date}`}>
              <Typography 
                variant={isDesktop ? 'subtitle1' : 'h4'}
                color='#BBBBBB'
              >
                {texts(
                  'list_date_label',
                  {
                    diff: moment(d.date).diff(moment().startOf('d'), 'd'),
                    date: texts('date_label', { date: moment(d.date).format('DD.MM.YYYY'), day: moment(d.date).day() })
                  }
                )}
              </Typography>
              <Grid
                container
                flexDirection='column'
                gap='.75rem'
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    marginTop: '1rem'
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginTop: '.5rem'
                  }
                }}
              >
                {
                  d.reservations.map(r => <ReservationCardView key={r.uuid} reservation={r} />)
                }
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  )
}
