import { Box, Button, CSSObject, Divider, Grid, IconButton, List, Drawer as MuiDrawer, Theme, Typography, styled, useTheme } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSignout } from '../../../hooks/auth/useSignout'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { desktopDrawerMenuItems, mobileDrawerMenuItems } from '../../../utils/const'
import { isCurrentLocationInPaths } from '../../../utils/drawer'
import CollapseDrawerIcon from '../../icons/CollapseDrawerIcon'
import ExpandDrawerIcon from '../../icons/ExpandDrawerIcon'
import LogoutIcon from '../../icons/LogoutIcon'
import DrawerItem from './DrawerItem'
import DrawerListSection from './DrawerListSection'
import { Add } from '@mui/icons-material'

const drawerOpenWidth = '18.5rem'
const drawerClosedWidth = '5rem'

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  width: drawerOpenWidth
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: drawerClosedWidth
})

const Drawer = styled(MuiDrawer)(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      ...(open ? openedMixin(theme) : closedMixin(theme))
    }  
  }),
)

type Props = {
  expanded: boolean
  onSwitchClick: () => void
  onLogout: () => void
}

export default function MenuDrawer(props: Props) {
  const theme = useTheme()
  const location = useLocation()
  const texts = useTranslation().t
  const navigate = useNavigate()

  const signoutMutation = useSignout(props.onLogout)

  const { isDesktop } = useGetDeviceType()

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        width: isDesktop
          ? (props.expanded ? '18.5rem' : '5rem')
          : 'auto' 
      }}
    >
      <Drawer
        variant={isDesktop ? 'permanent' : 'temporary'}
        open={props.expanded}
        {...(
          !isDesktop
          ? {
              anchor: 'right',
              onClose: props.onSwitchClick
            }
          : {}
        )}
      >
        <List>
          <Grid
            container
            justifyContent='center'
          >
            <Button 
              variant='contained'
              onClick={() => { 
                navigate('/locations')
                if (!isDesktop) {
                  props.onSwitchClick()
                }
              }}
              sx={{
                [theme.breakpoints.up('md')]: {
                  marginBottom: props.expanded ? 0 : '1rem !important'
                }
              }}
            >
              {
                props.expanded
                ? texts('drawer:reserve_button_label')
                : <Add sx={{ fontSize: '2rem !important' }} />
              }
            </Button>
          </Grid>
          {
            (isDesktop ? desktopDrawerMenuItems : mobileDrawerMenuItems).map((section, idx) => (
              <Fragment key={`menu-section-idx-${idx}`}>
                <DrawerListSection
                  expanded={props.expanded || !isDesktop}
                  label={section.i18 ? texts(section.i18.text) as string : null}
                />
                {
                  section.items.map((item, itemIdx) => (
                    <DrawerItem 
                      key={`menu-section-idx=${idx}-item-idx-${itemIdx}`}
                      expanded={props.expanded || !isDesktop}
                      icon={<item.icon />}
                      text={texts(item.i18.text) as string}
                      selected={isCurrentLocationInPaths(item.includedPaths, location)}
                      onClick={() => {
                        navigate(item.path)
                        if (!isDesktop) {
                          props.onSwitchClick()
                        }
                      }}
                    />
                  ))
                }
              </Fragment>
            ))
          }
        </List>
        <Divider sx={{margin: props.expanded || !isDesktop ? '1rem' : '2rem 0.625rem'}} />
        {
          isDesktop
          &&  <IconButton
                onClick={props.onSwitchClick}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: props.expanded ? 'flex-end' : 'center',
                  gap: '1.25rem',
                  justifyContent: 'flex-end',
                  paddingRight: props.expanded ? '1rem' : 'auto'
                }}
              >
                {
                  props.expanded
                  ? <CollapseDrawerIcon
                      sx={{
                        height: '1.25rem',
                        width: '1.5625rem'
                      }}
                    />
                  : <ExpandDrawerIcon
                      sx={{
                        height: '1.5rem',
                        width: '1.875rem'
                      }}
                    />
                }
                <Typography 
                  variant='minorButton'
                  color='#fff'
                  display={props.expanded ? 'block' : 'none'}
                >
                  {texts('drawer:collapse_drawer')}
                </Typography>
              </IconButton>
        }
        {
          !isDesktop
          &&  <DrawerItem 
                key={`menu-item-logout`}
                expanded
                icon={<LogoutIcon />}
                text={texts('drawer:logout')}
                onClick={() => {
                  signoutMutation.mutate()
                }}
              />
        }
        <Grid flexGrow={1}/>
        {
          (props.expanded || !isDesktop)
          &&  <Grid
                container
                justifyContent='center'
                sx={{
                  [theme.breakpoints.up('md')]: {
                    marginBlock: '2rem'
                  },
                  [theme.breakpoints.down('md')]: {
                    marginBlock: '2rem'
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginBlock: '1.5rem'
                  }
                }}
              >
                <Typography variant='menuLabel'>
                  {texts('drawer:version_label', { version: process.env.REACT_APP_VERSION })}
                </Typography>
              </Grid>
        }
      </Drawer>
    </Box >
  )
}