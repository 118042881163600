import { ArrowBack, Tune } from '@mui/icons-material'
import { Grid, IconButton, Popover, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useGetDeviceType from '../../../../hooks/device/useGetDeviceType'
import { ReservationFilterFormData } from '../../../../types/form/filter'
import Badge from '../../../customMui/Badge'
import FullScreenDialog from '../../../customMui/FullScreenDialog'
import PillButton from '../../../customMui/PillButton'
import FiltersForm from './FiltersForm'

type Props = {
  initValues?: ReservationFilterFormData
  isFilterPresent: boolean
  onChange: (data: ReservationFilterFormData) => void
}

const defaultInitialValues: ReservationFilterFormData = {
  locations: [],
  rooms: [],
  trainees: [],
  dateRange: []
}

export default function FiltersButton(props: Props) {
  const texts = useTranslation('reservationList').t

  const { isDesktop } = useGetDeviceType()
  
  const [initialValues, setInitialValues] = useState<ReservationFilterFormData>(props.initValues ?? defaultInitialValues)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSubmit = (data: ReservationFilterFormData) => {
    setInitialValues(data)
    props.onChange(data)
    handleClose()
  }

  return (
    <>
      {
        props.isFilterPresent
        ? <Badge>
            <PillButton
              variant='contained'
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget)
              }}
            > 
              <Tune 
                sx={{ 
                  marginRight: '.25rem',
                  marginTop: '-.125rem',
                  fontSize: '.875rem' 
                }} 
              />
              <Typography variant='button2'>
                {texts('general_filters_button_label')}
              </Typography>
            </PillButton>
          </Badge>
        : <PillButton
            variant='contained'
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget)
            }}
          >
            <Tune 
              sx={{ 
                marginRight: '.25rem',
                marginTop: '-.125rem',
                fontSize: '.875rem' 
              }} 
            />
            <Typography variant='button2'>
              {texts('general_filters_button_label')}
            </Typography>
          </PillButton>
      }
      {
        isDesktop &&
          <Popover 
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '.MuiPaper-root': {
                maxHeight: 'calc(100% - 12.25rem)'
              }
            }}
          >
            <Grid
              sx={{
                padding: '1rem',
                width: '23rem'
              }}
            >
              <FiltersForm
                initValues={initialValues}
                onSubmit={handleSubmit}
              />
            </Grid>
          </Popover>
      }
      {
        !isDesktop &&
          <FullScreenDialog
            open={Boolean(anchorEl)}
          >
            <Grid marginBottom='1rem'>
              <IconButton onClick={() => { setAnchorEl(null) }}>
                <ArrowBack 
                  sx={{
                    color: '#000',
                    fontSize: '2rem',
                    padding: 0
                  }}
                />
              </IconButton>
            </Grid>
            <FiltersForm
              initValues={initialValues}
              onSubmit={handleSubmit}
            />
          </FullScreenDialog>
      }
    </>
  )
}

