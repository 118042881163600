import { Grid, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'
import { LoginState } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { getUserName } from '../../services/storage/storageService'
import { contentContainerId } from '../../utils/const'
import ScrollToTop from '../common/ScrollToTop'
import MenuDrawer from '../customMui/MenuDrawer/MenuDrawer'
import { maxPageWidth } from '../themes/MainTheme'
import AppBar from './AppBar'

type Props = {
  children: React.ReactNode
}

export default function MainOverlay(props: Props) {
  const theme = useTheme()
  const [drawerExpanded, setDrawerExpanded] = useState(false)
  const [userName, setUserName] = useState<string>(getUserName() ?? '')

  const { isDesktop, isMobile } = useGetDeviceType()

  const handleDrawerSwitch = () => {
    setDrawerExpanded(!drawerExpanded)
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (userName !== store.getState().storage.userName) {
        setUserName(store.getState().storage.userName)
      }
    })
  
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      flexDirection='column'
      width='100%'
      alignItems='center'
      overflow='hidden'
      flexWrap='nowrap'
      height='100%'
      maxHeight='100%'
      sx={{
        [theme.breakpoints.up('md')]: {
          height: '100vh',
          maxHeight: '100vh'
        },
        [theme.breakpoints.down('md')]: {
          height: '100%',
          maxHeight: '100%'
        }
      }}
    > 
      <Grid
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10
        }}
      >
        <AppBar 
          loggedIn={store.getState().storage.loginState === LoginState.LOGGED}
          userName={getUserName() ?? ''}
          onMenuButtonClick={handleDrawerSwitch}
        />
      </Grid>
      {
        store.getState().storage.loginState === LoginState.LOGGED
        ? <Grid 
            item
            container
            flexGrow={1}
            flexWrap='nowrap'
            width='100%'
            marginTop={isMobile ? '3rem' : '3.5rem'}
          >
            <MenuDrawer
              onSwitchClick={handleDrawerSwitch}
              expanded={drawerExpanded}
              onLogout={() => { setDrawerExpanded(false) }}
            />
            <Grid
              id={contentContainerId}
              container 
              item
              width={
                isDesktop 
                ? `calc(100% - ${drawerExpanded ? 18.5 : 5}rem)`
                : '100%'
              }
              flexGrow={1}
              justifyContent='center'
              sx={{
                float: 'right',
                overflowY: 'auto',
                backgroundColor: '#FAFAFA',
                transition: theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: drawerExpanded ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
                }),
                [theme.breakpoints.up('md')]: {
                  maxHeight: 'calc(100vh - 3.5rem)'
                },
                [theme.breakpoints.down('md')]: {
                  maxHeight: 'calc(100% - 3.5rem)'
                },
                [theme.breakpoints.down('sm')]: {
                  maxHeight: 'calc(100% - 3rem)'
                }
              }}
            >
              <ScrollToTop containerId={contentContainerId} />
              <Grid
                item
                width='100%'
                height='100%'
                maxWidth={maxPageWidth}
              >             
                {props.children}
              </Grid>
            </Grid>
          </Grid>
        : <Grid marginTop={isMobile ? '3rem' : '3.5rem'}>
            {props.children}
          </Grid>
      }
    </Grid>
  )
}