import { ContactDto } from './auth/types'

export enum ProviderType {
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
  PASSWORD = 'password'
}

export type AccountDto = {
  uuid: string
  firstName: string
  lastName: string
  nick: string | null
  newsletter: boolean
  email: string | null
  oAuthProvider: ProviderType
  demoEntries: number
  demo: boolean
  contact: ContactDto
  package: PackageDto | null
}

export type PackageDto = {
  uuid: string
  totalEntries: number
  leftEntries: number
  validUntil: string
}

export type UpdatePackageDto = Pick<AccountDto, 'demo' | 'package' | 'demoEntries'>
