import { CalendarMonthOutlined, ConfirmationNumberOutlined } from '@mui/icons-material'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ProductCardViewActions from './ProductCardViewActions'
import { Product } from '../../../types/product'
import RemoveProductDialog from '../../dialogs/RemoveProductDialog'
import { useState } from 'react'

type Props = {
  product: Product
}

export default function ProductCardView(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('paymentsConfiguration').t

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false)

  return (
    <>
      <RemoveProductDialog
        open={isRemoveDialogOpen}
        uuid={props.product.uuid}
        i18n={{
          entryCount: texts('entry_count', { count: props.product.count }),
          validFor: props.product.validFor >= 30
            ? texts('valid_for_months', { months: (props.product.validFor / 30).toFixed() })
            : texts('valid_for_days', { days: props.product.validFor }),
          price: texts('price', { price: props.product.price / 100 })
        }}
        onClose={() => { setIsRemoveDialogOpen(false) }}
      />
      <Card sx={{ position: 'relative', maxWidth: '100%' }} >
        <CardContent sx={{ marginRight: '1.5rem' }} >
          <Grid
            container
            gap='1rem'
            flexWrap='nowrap'
          >
            <Grid
              container
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              gap='.25rem'
              flexShrink={0}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
                [theme.breakpoints.up('md')]: {
                  padding: '.5rem',
                  width: '6.25rem'
                },
                [theme.breakpoints.down('md')]: {
                  padding: '.5rem',
                  width: '6.25rem'
                },
                [theme.breakpoints.down('sm')]: {
                  padding: '.25rem',
                  width: '5.25rem'
                }
              }}
            >
              <Typography
                variant='body1'
                fontWeight={700}
              >
                {texts('price', { price: props.product.price / 100 })}
              </Typography>
            </Grid>
            <Grid
              container
              flexDirection='column'
              justifyContent='center'
              gap='1rem'
            >
              <Grid
                item
                container
                alignItems='center'
                gap='.5rem'
              >
                <ConfirmationNumberOutlined sx={{ marginTop: '-.125rem' }} />
                <Typography 
                  variant='body1' 
                  fontWeight={700}
                >
                  {texts('entries_count')}
                </Typography>
                <Typography 
                  variant='body1'
                  sx={{ marginTop: '.125rem' }}
                >
                  {props.product.count}
                </Typography>
              </Grid>
              <Grid
                item
                container
                alignItems='center'
                gap='.5rem'
              >
                <CalendarMonthOutlined sx={{ marginTop: '-.25rem' }} />
                <Typography 
                  variant='body1' 
                  fontWeight={700}
                >
                  {texts('product_valid_for')}
                </Typography>
                <Typography 
                  variant='body1'
                  sx={{ marginTop: '.125rem' }}
                >
                  {
                    props.product.validFor >= 30
                      ? texts('valid_for_months', { months: (props.product.validFor / 30).toFixed() })
                      : texts('valid_for_days', { days: props.product.validFor })
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <ProductCardViewActions 
          product={props.product}
          onRemove={() => {
            setIsRemoveDialogOpen(true)
          }}
        />
      </Card>
    </>
  )
}
