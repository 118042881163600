import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { ProductFormData, ValidForUnit } from '../../../types/form/payments'
import { productFormDataValidation } from '../../../validations/payments'
import FormikSelectField from '../../customMui/FormikSelectField'
import NumericTextField from '../../customMui/NumericTextField'
import TextField from '../../customMui/TextField'
import { useCreateTrainerProduct } from '../../../hooks/data/product/useCreateTrainerProduct'
import { mapProductFormData } from '../../../mapping/product'
import { useNavigate, useParams } from 'react-router-dom'
import { useEditTrainerProduct } from '../../../hooks/data/product/useEditTrainerProduct'

type Props = {
  isEditPage: boolean
  initialValues?: ProductFormData
}

const defaultInitialValues: ProductFormData = {
  count: null,
  validForCount: null,
  validForUnit: ValidForUnit.MONTH,
  price: '',
  taxRate: ''
}

export default function MainSection(props: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const texts = useTranslation('productPage').t

  const { uuid } = useParams<{ uuid?: string }>()

  const addMutation = useCreateTrainerProduct()
  const editMutation = useEditTrainerProduct()

  const handleSubmit = (data: ProductFormData) => {
    if (props.isEditPage) {
      editMutation.mutate({
        uuid: uuid!,
        body: mapProductFormData(data)
      }, {
        onSuccess: () => {
          navigate('/payments/configuration', { replace: true })
        }
      })
    } else {
      addMutation.mutate(mapProductFormData(data), {
        onSuccess: () => {
          navigate('/payments/configuration', { replace: true })
        }
      })
    }
  }

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
    >
      <Grid
        marginTop='1.5rem'
        sx={{
          [theme.breakpoints.up('md')]: {
            gap: '1.5rem',
            width: '80%'
          },
          [theme.breakpoints.down('md')]: {
            gap: '1rem',
            width: '100%'
          },
          [theme.breakpoints.down('sm')]: {
            gap: '.75rem',
            width: '100%'
          }
        }}
      >
        <Card sx={{ width: '100%' }}>
          <CardContent
            sx={{
              [theme.breakpoints.up('md')]: {
                padding: '2rem',
                ':last-child': {
                  padding: '2rem'
                }
              },
              [theme.breakpoints.down('md')]: {
                padding: '1rem',
                ':last-child': {
                  padding: '1rem'
                }
              }
            }}
          >
            <Grid>
              <Formik<ProductFormData>
                initialValues={props.initialValues ?? defaultInitialValues}
                onSubmit={handleSubmit}
                validationSchema={productFormDataValidation(texts)}
              >
                {(formikProps) => (
                  <Form>
                    <Grid
                      container
                      flexDirection='column'
                      alignItems='center'
                    >
                      <Grid
                        sx={{
                          [theme.breakpoints.up('sm')]: {
                            width: '80%'
                          },
                          [theme.breakpoints.down('sm')]: {
                            width: '100%'
                          }
                        }}
                      >
                        {
                          props.isEditPage &&
                            <Typography 
                              variant='body1' 
                              fontWeight={700}
                              color='#E02020' 
                              sx={{ marginBottom: '1rem' }}
                            >
                              {texts('edit_warning')}
                            </Typography>
                        }
                        <TextField
                          name='count'
                          label={texts('training_count_label')}
                          type='number'
                          inputProps={{
                            min: 1
                          }}
                        />
                        <Grid
                          container
                          flexWrap='nowrap'
                          sx={{
                            [theme.breakpoints.up('sm')]: {
                              gap: '1rem'
                            },
                            [theme.breakpoints.down('sm')]: {
                              flexDirection: 'column'
                            }
                          }}
                        >
                          <TextField
                            name='validForCount'
                            type='number'
                            label={texts('valid_for_count_label')}
                            inputProps={{
                              min: 1
                            }}
                          />
                          <FormikSelectField
                            name='validForUnit'
                            label={texts('time_unit_label')}
                            options={texts('valid_for_unit_select_options', { returnObject: true })}
                          />
                        </Grid>
                        <Grid
                          container
                          flexWrap='nowrap'
                          alignItems='flex-start'
                          sx={{
                            [theme.breakpoints.up('sm')]: {
                              gap: '1rem'
                            },
                            [theme.breakpoints.down('sm')]: {
                              flexDirection: 'column'
                            }
                          }}
                        >
                          <Grid
                            container
                            flexWrap='nowrap'
                            alignItems='center'
                            gap='.5rem'
                          >
                            <NumericTextField 
                              name='price' 
                              label={texts('product_price_label')}
                            />
                            <Typography
                              variant='body1'
                              sx={{ 
                                marginBottom: formikProps.errors.price && formikProps.touched.price 
                                  ? '2.1325rem'
                                  : '1rem'
                                }}
                            >
                              {texts('currency_unit')}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            flexWrap='nowrap'
                            alignItems='center'
                            gap='.5rem'
                          >
                            <NumericTextField 
                              name='taxRate' 
                              label={texts('tax_rate_label')}
                            />
                            <Typography
                              variant='body1'
                              sx={{ 
                                marginBottom: formikProps.errors.taxRate && formikProps.touched.taxRate 
                                  ? '2.1325rem'
                                  : '1rem'
                              }}
                            >
                              {texts('percentage_sign')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      marginTop='1rem'
                      justifyContent='flex-end'
                    >
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        size='medium'
                        loading={addMutation.isLoading || editMutation.isLoading}
                        disabled={!formikProps.dirty || !formikProps.isValid}
                        sx={{
                          width: '15rem',
                          height: '2.25rem !important',
                          [theme.breakpoints.up('md')]: {
                            marginBottom: '0',
                            marginRight: '0'
                          },
                          [theme.breakpoints.down('md')]: {
                            marginBottom: '1rem',
                            marginRight: '1rem'
                          },
                          [theme.breakpoints.down('sm')]: {
                            marginBottom: '1rem',
                            marginRight: '0'
                          }
                        }}
                      >
                        {texts(props.isEditPage ? 'edit_submit' : 'create_submit')}
                      </LoadingButton>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}