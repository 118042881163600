import { AccessTime, AccountCircleOutlined, LocationOnOutlined } from '@mui/icons-material'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { ReservationDetails, ReservationStatus } from '../../../types/reservations'
import OutlinedBadge from '../../common/OutlinedBadge'
import Link from '../../customMui/Link'
import { mapReservationStatusToColor } from '../../mapping/reservations'
import ControlSection from './ControlSection'
import InfoBlock from './InfoBlock'
import LocationButtons from './LocationButtons'
import Step from './Step'

type Props = {
  reservation: ReservationDetails
}

export default function MainSection(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('reservationDetails').t
  const { isMobile } = useGetDeviceType()

  const importantInformations = texts('important_informations', { returnObjects: true })

  return (
    <Grid
      container
      justifyContent='center'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingBlock: '1.5rem',
        },
        [theme.breakpoints.down('md')]: {
          paddingBlock: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '1rem'
        }
      }}
    >
      <Card 
        sx={{ 
          [theme.breakpoints.up('md')]: {
            width: '80%',
          },
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
          '.MuiCardContent-root': {
            [theme.breakpoints.up('md')]: {
              padding: '2rem',
              ':last-child': {
                padding: '2rem'
              }
            },
            [theme.breakpoints.down('md')]: {
              padding: '2rem',
              ':last-child': {
                padding: '2rem'
              }
            },
            [theme.breakpoints.down('sm')]: {
              padding: '1rem',
              ':last-child': {
                padding: '1rem'
              }
            }
           
          }
        }}
      >
        <CardContent>
          {
            props.reservation.status !== ReservationStatus.ACTIVE &&
              <Grid marginBottom='1rem'>
                <Typography variant='h4' color='#BBBBBB'>
                  {texts('status_section_title')}
                </Typography>
                <Grid
                  width='max-content'
                  marginTop='1rem'
                >
                  <OutlinedBadge 
                    color={mapReservationStatusToColor(props.reservation.status)}
                    label={texts('reservation_status_label', { status: props.reservation.status })}
                  />
                </Grid>
              </Grid>
          }
          <Grid>
            <Typography variant='h4' color='#BBBBBB'>
              {texts('date_section_title')}
            </Typography>
            <InfoBlock icon={AccessTime}>
              <Grid 
                gridArea='1 / 2 / 2 / 3'
                container
                alignItems='center'
              >
                <Typography 
                  variant='body1'
                  fontWeight={700}
                >
                  {texts('date_label', {
                    date: props.reservation.event.startDate.format('DD.MM.YYYY'),
                    time: props.reservation.event.formattedStartTime
                  })}
                </Typography>
              </Grid>
            </InfoBlock>
          </Grid>
          <Grid marginTop='1rem'>
            <Typography variant='h4' color='#BBBBBB'>
              {texts('location_section_title')}
            </Typography>
            <InfoBlock icon={LocationOnOutlined}>
              <Grid 
                gridArea='1 / 2 / 2 / 3'
                container
                alignItems='center'
                gap='1rem'
              >
                <Typography 
                  variant='body1'
                  fontWeight={700}
                >
                  {texts(
                    'location_label',
                    {
                      location: props.reservation.place.name,
                      room: props.reservation.trainingRoom.name
                    }
                  )}
                </Typography>
              </Grid>
              <Grid gridArea='2 / 2 / 3 / 3'>
                <Typography variant='body1'>
                  {texts(
                    'address_label',
                    {
                      streetAddress: props.reservation.place.address.streetAddress,
                      postalCode: props.reservation.place.address.postalCode,
                      town: props.reservation.place.address.town
                    }
                  )}
                </Typography>
              </Grid>
              {
                props.reservation.status === ReservationStatus.ACTIVE &&
                  <Grid 
                    gridArea='3 / 2 / 4 / 3'
                    sx={{
                      [theme.breakpoints.up('sm')]: {
                        paddingTop: '.1875rem',
                      },
                    }}
                  >
                    <LocationButtons reservation={props.reservation} />
                  </Grid>
              }
            </InfoBlock>
          </Grid>
          {
            props.reservation.trainee != null &&
              <Grid marginTop='1rem'>
                <Typography variant='h4' color='#BBBBBB'>
                  {texts('client_section_title')}
                </Typography>
                <InfoBlock icon={AccountCircleOutlined}>
                  <Grid 
                    gridArea='1 / 2 / 2 / 3'
                    container
                    alignItems='center'
                  >
                    <Typography 
                      variant='body1'
                      fontWeight={700}
                    >
                      {props.reservation.trainee.fullName}
                    </Typography>
                  </Grid>
                  <Grid gridArea='2 / 2 / 3 / 3'>
                    <Typography 
                      variant='body1'
                      sx={{
                        lineHeight: '1.5rem !important'
                      }}
                    >
                      {texts('client_section_description_first_part')}
                      <Link to={`mailto:${props.reservation.trainee.contactEmail}`}> 
                        {props.reservation.trainee.contactEmail}
                      </Link>
                      {texts('client_section_description_second_part')}
                      <Link to={`tel:${props.reservation.trainee.phone}`}> 
                        {props.reservation.trainee.phone}
                      </Link>
                    </Typography>
                  </Grid>
                </InfoBlock>
              </Grid>
          }
          {
            props.reservation.status === ReservationStatus.ACTIVE &&
              <>
                <Grid marginTop='1rem'>
                  <Typography variant='h4' color='#BBBBBB'>
                    {texts('access_code_section_title')}
                  </Typography>
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                      backgroundColor: 'rgba(187 187 187 / 0.2)',
                      [theme.breakpoints.up('md')]: {
                        marginTop: '1rem',
                        paddingBlock: '1rem'
                      },
                      [theme.breakpoints.down('md')]: {
                        marginTop: '1rem',
                        paddingBlock: '.875rem'
                      },
                      [theme.breakpoints.down('sm')]: {
                        marginTop: '.75rem',
                        paddingBlock: '.75rem'
                      }
                    }}
                  >
                    <Typography
                      variant='h1'
                      component='p'
                      sx={{
                        letterSpacing: 5
                      }}
                    >
                      {props.reservation.passcode}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid marginTop='1rem'>
                  <Typography variant='h4' color='#BBBBBB'>
                    {texts('important_info_section_title')}
                  </Typography>
                  <Grid
                    container
                    sx={{
                      [theme.breakpoints.up('sm')]: {
                        marginTop: '1rem',
                        gap: '1rem'
                      },
                      [theme.breakpoints.down('sm')]: {
                        marginTop: '.75rem',
                        gap: '.75rem'
                      }
                    }}
                  >
                    {
                      importantInformations.map((e, idx) => (
                        <Step 
                          step={idx + 1}
                          title={e.title}
                          description={e.description}
                        />
                      ))
                    }
                  </Grid>
                </Grid>
                <Grid marginTop='1rem'>
                  <Typography variant='h4' color='#BBBBBB'>
                    {texts('contact_section_title')}
                  </Typography>
                  <Grid
                    sx={{
                      [theme.breakpoints.up('sm')]: {
                        marginTop: '1rem'
                      },
                      [theme.breakpoints.down('sm')]: {
                        marginTop: '.75rem'
                      }
                    }}
                  >
                    <Typography 
                      variant='body1'
                      sx={{
                        lineHeight: '1.5rem !important'
                      }}
                    >
                      {texts('contact_description_first_part')}
                      <Link to={`mailto:${props.reservation.place.contact.email}`}> 
                        {props.reservation.place.contact.email}
                      </Link>
                      {texts('contact_description_second_part')}
                      <Link to={`tel:${props.reservation.place.contact.phone}`}> 
                        {props.reservation.place.contact.phone}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </>
          }
          {
            isMobile &&
              <Grid marginTop='1.5rem'>
                <ControlSection reservation={props.reservation} />
              </Grid>
          }
        </CardContent>
      </Card>
    </Grid>
  )
}