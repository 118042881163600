import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { PaymentConfigurationFormData } from '../../../types/form/payments'
import { paymentConfigurationFormDataValidation } from '../../../validations/payments'
import TextField from '../../customMui/TextField'
import { StripeConfig } from '../../../types/payments'
import { useEditStripeConfig } from '../../../hooks/data/payments/useEditStripeConfig'
import { mapPaymentConfigurationFormData } from '../../../mapping/payments'
import { useRemoveStripeConfig } from '../../../hooks/data/payments/useRemoveStripeConfig'

type Props = {
  config: StripeConfig | null
}

export default function PaymentSettingsCardView(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('paymentsConfiguration').t

  const editMutation = useEditStripeConfig()
  const removeMutation = useRemoveStripeConfig()

  const handleSubmit = (data: PaymentConfigurationFormData) => {
    editMutation.mutate(mapPaymentConfigurationFormData(data))
  }
  
  return (
    <Card sx={{ width: '100%' }}>
      <CardContent
        sx={{
          [theme.breakpoints.up('md')]: {
            padding: '2rem',
            ':last-child': {
              padding: '2rem'
            }
          },
          [theme.breakpoints.down('md')]: {
            padding: '1rem',
            ':last-child': {
              padding: '1rem'
            }
          }
        }}
      >
        <Typography variant='h4' color='#BBBBBB'>
          {texts('payment_setting_card_title')}
        </Typography>
        <Typography variant='body1' sx={{ marginTop: '1rem' }} >
          {texts('payment_setting_card_description')}
        </Typography>
        <Grid
          sx={{
            [theme.breakpoints.up('md')]: {
              marginTop: '1.5rem'
            },
            [theme.breakpoints.down('md')]: {
              marginTop: '1rem'
            }
          }}
        >
          <Formik<PaymentConfigurationFormData>
            initialValues={props.config ?? {
              secretKey: '',
              publicKey: ''
            }}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={paymentConfigurationFormDataValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <Grid
                  container
                  flexDirection='column'
                  alignItems='center'
                >
                  <Grid
                    sx={{
                      [theme.breakpoints.up('sm')]: {
                        width: '80%'
                      },
                      [theme.breakpoints.down('sm')]: {
                        width: '100%'
                      }
                    }}
                  >
                    <TextField
                      name='secretKey'
                      label={texts('secret_key_field_label')}
                    />
                    <TextField
                      name='publicKey'
                      label={texts('public_key_field_label')}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  marginTop='1rem'
                  justifyContent='flex-end'
                  gap='1rem'
                  flexWrap='nowrap'
                  sx={{
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                      gap: '1rem'
                    },
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'row',
                      gap: '1rem'
                    },
                    [theme.breakpoints.down('sm')]: {
                      flexDirection: 'column',
                      gap: '.25rem'
                    }
                  }}
                >
                  {
                    props.config &&
                      <LoadingButton
                        type='button'
                        variant='contained'
                        size='medium'
                        loading={editMutation.isLoading || removeMutation.isLoading}
                        sx={{
                          width: '15rem',
                          height: '2.25rem !important',
                          backgroundColor: theme.palette.error.main,
                          '&.MuiLoadingButton-loading': {
                            backgroundColor: theme.palette.error.main,
                          },
                          [theme.breakpoints.up('md')]: {
                            marginBottom: '0',
                            marginRight: '0'
                          },
                          [theme.breakpoints.down('md')]: {
                            marginBottom: '1rem',
                            marginRight: '1rem',
                            maxWidth: '50%'
                          },
                          [theme.breakpoints.down('sm')]: {
                            marginBottom: '1rem',
                            marginRight: '0',
                            maxWidth: '100%',
                            width: '100%'
                          }
                        }}
                        onClick={() => {
                          removeMutation.mutate()
                        }}
                      >
                        {texts('remove_button_label')}
                      </LoadingButton>
                  }
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    size='medium'
                    loading={editMutation.isLoading || removeMutation.isLoading}
                    disabled={!formikProps.dirty || !formikProps.isValid}
                    sx={{
                      width: '15rem',
                      height: '2.25rem !important',
                      [theme.breakpoints.up('md')]: {
                        marginBottom: '0',
                        marginRight: '0'
                      },
                      [theme.breakpoints.down('md')]: {
                        marginBottom: '1rem',
                        marginRight: '1rem',
                        maxWidth: '50%'
                      },
                      [theme.breakpoints.down('sm')]: {
                        marginBottom: '1rem',
                        marginRight: '0',
                        maxWidth: '100%',
                        width: '100%'
                      }
                    }}
                  >
                    {texts('submit_button_label')}
                  </LoadingButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </CardContent>
    </Card>
  )
}
