import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Product } from '../../../api/data/types'
import { mapListEntryDto } from '../../../mapping/product'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export function useCreateTrainerProduct() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (body: Product.ProductBodyDto) => {
    const { data } = await dataApi.createTrainerProduct(body)
    return mapListEntryDto(data.data!)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryNames.getTrainerProductList])
      toast.success(texts('successes:trainer_product_added'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}