import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import AuthApi from '../../api/auth/AuthApi'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'
import { toast } from 'react-hot-toast'

const authApi = AuthApi.getInstance()

export function useStartPwdReset() {
  const texts = useTranslation().t

  const mutation = useMutation(async (email: string) => {
    const { data } = await authApi.startPwdReset({ email: email })
    return data
  }, {
    onSuccess: () => {
      toast.success(texts('successes:start_pwd_reset'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}