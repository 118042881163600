import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ProfileSettingIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
    >
      <g id="profileIcon" fillRule="nonzero" strokeWidth="0.5">
        <path d="M4.31876923,4.92307692 C4.31876923,2.20413662 6.52290585,0 9.24184615,0 C11.9607865,0 14.1649231,2.20413662 14.1649231,4.92307692 C14.1649231,7.64201723 11.9607865,9.84615385 9.24184615,9.84615385 C6.52290585,9.84615385 4.31876923,7.64201723 4.31876923,4.92307692 L4.31876923,4.92307692 Z M16.6855385,14.3753846 C16.6694711,14.2617097 16.614181,14.1572244 16.5292308,14.08 C15.2240477,12.8576291 13.4782641,12.2179527 11.6923077,12.3076923 L6.76923077,12.3076923 C1.77230769,12.3076923 -1.63971401e-15,15.9630769 -1.63971401e-15,19.1015385 C-1.63971401e-15,21.9076923 1.48923077,23.3846154 4.30769231,23.3846154 L10.7076923,23.3846154 C10.8059287,23.3874198 10.9004354,23.3469169 10.9661538,23.2738462 C11.0346232,23.2024354 11.074103,23.1081226 11.0769231,23.0092308 L11.0769231,20.9230769 C11.077354,20.4336114 11.2721416,19.9643504 11.6184615,19.6184615 L16.5784615,14.6338462 C16.6456969,14.5644266 16.6839821,14.472014 16.6855385,14.3753846 L16.6855385,14.3753846 Z M12.9230769,20.9230769 L12.9230769,24 L16,24 L21.5864615,18.4381538 L18.4861538,15.3378462 L12.9230769,20.9230769 Z M23.6406154,14.6596923 L22.2621538,13.2812308 C21.7821077,12.8040325 21.0068153,12.8040325 20.5267692,13.2812308 L19.3464615,14.4750769 L22.4467692,17.5753846 L23.6406154,16.3950769 C24.1169986,15.9151748 24.1169986,15.1408252 23.6406154,14.6609231 L23.6406154,14.6596923 Z" id="Shape"></path>
      </g>
    </SvgIcon>
  )
}