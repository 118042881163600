import { Box, Button, Grid, useTheme } from '@mui/material'

type Props = {
  icon: string
  bgColor: string
  onClick: () => void
}

export default function OauthButton(props: Props) {
  const theme = useTheme()

  return (
    <Button
      variant='contained'
      sx={{
        backgroundColor: props.bgColor,
        '&:hover': {
          backgroundColor: props.bgColor
        },
        padding: 0,
        boxShadow: 3,
        minWidth: 'unset',
        [theme.breakpoints.up('md')]: {
          width: '3rem',
          height: '3rem'
        },
        [theme.breakpoints.down('md')]: {
          width: '3rem',
          height: '3rem'
        },
        [theme.breakpoints.down('sm')]: {
          width: '2.5rem',
          height: '2.5rem'
        }
      }}
      onClick={props.onClick}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='100%'
      >
        <Box 
          component='img'
          src={props.icon}
          sx={{
            [theme.breakpoints.up('md')]: {
              height: '1.5rem'
            },
            [theme.breakpoints.down('md')]: {
              height: '1.5rem'
            },
            [theme.breakpoints.down('sm')]: {
              height: '1.25rem'
            }
          }}
        />
      </Grid>
    </Button>
  )
}