import { Badge as MuiBadge } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode | ReactNode[]
}

export default function Badge(props: Props) {
  return (
    <MuiBadge 
      badgeContent=''
      sx={{
        '.MuiBadge-badge': {
          backgroundColor: '#66C4D6',
          height: '.75rem',
          width: '.75rem',
          minWidth: '.75rem',
          transform: 'translate(0, -25%)'
        }
      }}
    >
      {props.children}
    </MuiBadge>
  )
}