import { Grid, Typography, useTheme } from '@mui/material'

type Props = {
  step: number
  title: string
  description: string
}

export default function Step(props: Props) {
  const theme = useTheme()

  return (
    <Grid
      display='grid'
      gridTemplateColumns='auto 1fr'
      columnGap='.75rem'
    >
      <Grid 
        gridArea='1 / 1 / 2 / 2'
        container
        alignItems='center'
      >
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          sx={{
            backgroundColor: 'RGBA(92, 15, 139, 0.1)',
            borderRadius: '50%',
            [theme.breakpoints.up('sm')]: {
              height: '2.25rem',
              width: '2.25rem'
            },
            [theme.breakpoints.down('sm')]: {
              height: '2rem',
              width: '2rem'
            }
          }}
        >
          <Typography
            variant='body1'
            fontWeight={700}
          >
            {props.step}
          </Typography>
        </Grid>
      </Grid>
      <Grid 
        gridArea='1 / 2 / 2 / 3'
        container
        alignItems='center'
      >
        <Typography
          variant='body1'
          fontWeight={700}
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid gridArea='2 / 2 / 3 / 3'>
        <Typography variant='body1'>
          {props.description}
        </Typography>
      </Grid>
    </Grid>
  )
}