import { TrainingRoomOption } from '../types/filters'
import { SearchData } from '../types/rooms'

export function mapSearchDataList(data: SearchData[]): TrainingRoomOption[] {
  return data.map(mapSearchData)
}

export function mapSearchData(data: SearchData): TrainingRoomOption {
  return {
    value: data.uuid,
    label: data.name,
    active: data.active
  }
}