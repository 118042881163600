import { AxiosResponse } from 'axios'
import { Success } from '../../types/common'
import { Api } from '../Api'
import { AppleLoginDto, AppleRegistrationDto, AuthDto, EmailCheckDto, EmailDto, FacebookRegistrationDto, GoogleRegistrationDto, PasswordDto, LoginDto, RegistrationDto, TokenDto, VerifyEmailDto } from './types'

export default class AuthApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_AUTH}/api/v1/trainer/auth/account`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
  private static instance: AuthApi

  static getInstance(): AuthApi {
    if (AuthApi.instance) {
      return this.instance
    }
    return this.instance = new AuthApi()
  }

  public signout(): Promise<AxiosResponse<Success>> {
    return this.post<Success>('/logout')
  }

  public login(data: LoginDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/login', data)
  }

  public appleLogin(data: AppleLoginDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/oauth/apple/login', data)
  }

  public facebookLogin(data: TokenDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/oauth/facebook/login', data)
  }

  public googleLogin(data: TokenDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/oauth/google/login', data)
  }

  public emailCheck(data: EmailDto): Promise<AxiosResponse<EmailCheckDto>> {
    return this.post<EmailCheckDto>('/email/check', data)
  }

  public register(data: RegistrationDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/register', data)
  }

  public appleRegister(data: AppleRegistrationDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/oauth/apple/register', data)
  }

  public facebookRegister(data: FacebookRegistrationDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/oauth/facebook/register', data)
  }

  public googleRegister(data: GoogleRegistrationDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/oauth/google/register', data)
  }

  public verifyEmail(data: VerifyEmailDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/email/verification-code/check', data)
  }

  public resendVerificationEmail(data: EmailDto): Promise<AxiosResponse<Success>> {
    return this.post<Success>('/email/verification-code/resend', data)
  }

  public startPwdReset(data: EmailDto): Promise<AxiosResponse<Success>> {
    return this.post<Success>('/password/reset/start', data)
  }

  public checkPwdResetCode(data: VerifyEmailDto): Promise<AxiosResponse<TokenDto>> {
    return this.post<TokenDto>('/password/reset/verification-code/check', data)
  }

  public finishPasswordReset(data: PasswordDto, token: string): Promise<AxiosResponse<Success>> {
    return this.patch<Success>('/password/reset/finish', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  }
}