import { ArrowBack, VisibilityOutlined } from '@mui/icons-material'
import { Button, Grid, IconButton } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LocationDetails } from '../../../types/locations'
import FullScreenDialog from '../../customMui/FullScreenDialog'
import InfoCardViewContent from './InfoCardViewContent'

type Props = {
  location: LocationDetails
}

export default function InfoButton(props: Props) {
  const texts = useTranslation('locationDetails').t
  
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  return (
    <>
      <Button
        variant='text'
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }}
      > 
        <Grid
          container
          alignItems='center'
          sx={{
            color: '#66C4D6'
          }}
        >
          <VisibilityOutlined
            sx={{
              fontSize: '1.125rem'
            }}
          />
          {texts('info_button_label')}
        </Grid>
      </Button>
      <FullScreenDialog
        open={Boolean(anchorEl)}
      >
        <Grid marginBottom='1rem'>
          <IconButton onClick={() => { setAnchorEl(null) }}>
            <ArrowBack
              sx={{
                color: '#000',
                fontSize: '2rem',
                padding: 0
              }}
            />
          </IconButton>
        </Grid>
        <InfoCardViewContent location={props.location} />
      </FullScreenDialog>
    </>
  )
}

