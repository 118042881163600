import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AuthApi from '../../api/auth/AuthApi'
import { GoogleRegistrationDto } from '../../api/auth/types'
import { logIn } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'

const authApi = AuthApi.getInstance()

export function useGoogleRegister() {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const mutation = useMutation(async (body: GoogleRegistrationDto) => {
    const { data } = await authApi.googleRegister(body)
    return data
  }, {
    onSuccess: (data) => {
      store.dispatch(logIn(data))
      navigate('/')
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}