import moment from 'moment'
import { Reservation } from '../api/data/types'
import { Reservation as ReservationType, ReservationDetails, ReservationList, ListEvent, Event } from '../types/reservations'
import { mapListTraineeDto, mapTraineeDto } from './trainees'
import { SummaryScheduleDay } from '../types/trainees'

export function mapListDto(data: Reservation.ListDto): ReservationList {
  return {
    totalCount: data.totalCount,
    reservations: data.reservations.map(mapListEntryDto)
  }
}

export function mapListEntryDto(reservation: Reservation.ListEntryDto): ReservationType {
  return {
    uuid: reservation.uuid,
    status: reservation.status,
    event: mapListEventDto(reservation.event),
    place: reservation.place,
    trainingRoom: reservation.trainingRoom,
    trainee: reservation.trainee ? mapListTraineeDto(reservation.trainee) : undefined
  }
}

export function mapDetailsDto(reservation: Reservation.DetailsDto): ReservationDetails {
  return {
    uuid: reservation.uuid,
    status: reservation.status,
    passcode: reservation.passcode,
    event: mapEventDto(reservation.event),
    place: reservation.place,
    trainingRoom: reservation.trainingRoom,
    trainee: reservation.trainee ? mapTraineeDto(reservation.trainee) : undefined,
  }
}

export function mapListEventDto(event: Reservation.ListEventDto): ListEvent {
  return {
    uuid: event.uuid,
    formattedDate: event.date,
    formattedStartTime: event.startTime,
    startDate: moment(`${event.date} ${event.startTime}`, 'YYYY-MM-DD HH:mm')
  }
}

export function mapEventDto(event: Reservation.EventDto): Event {
  return {
    uuid: event.uuid,
    formattedDate: event.date,
    formattedStartTime: event.startTime,
    startDate: moment(`${event.date} ${event.startTime}`, 'YYYY-MM-DD HH:mm'),
    duration: event.duration
  }
}

export function mapReservationArr(data: ReservationType[]): SummaryScheduleDay[] {
  const schedule: SummaryScheduleDay[] = []
  let idx
  for (const reservation of data) {
    idx = schedule.findIndex(s => s.date === reservation.event.formattedDate)
    if (idx === -1) {
      schedule.push({
        date: reservation.event.formattedDate,
        reservations: [reservation]
      })
    } else {
      schedule[idx].reservations.push(reservation)
    }
  }
  return schedule.sort((a, b) => moment(a.date).valueOf()  - moment(b.date).valueOf())
}
