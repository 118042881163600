import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import TextField from '../../customMui/TextField'
import { InviteTraineeFormData } from '../../../types/form/trainees'
import { inviteTraineeFormDataValidation } from '../../../validations/trainee'
import { useInviteTrainee } from '../../../hooks/data/trainees/useInviteTrainee'
import { mapInviteTraineeFormData } from '../../../mapping/trainees'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'

type Props = {
  connectionCode: string
}

export default function MainSection(props: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const texts = useTranslation('inviteTrainee').t

  const mutation = useInviteTrainee()

  const handleSubmit = (data: InviteTraineeFormData) => {
    mutation.mutate(mapInviteTraineeFormData(data), {
      onSuccess: () => {
        navigate('/trainees')
      }
    })
  }

  return (
    <Grid
      container
      justifyContent='center'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingBlock: '1.5rem',
        },
        [theme.breakpoints.down('md')]: {
          paddingBlock: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '1rem'
        }
      }}
    >
      <Card 
        sx={{ 
          [theme.breakpoints.up('md')]: {
            width: '80%',
          },
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
          '.MuiCardContent-root': {
            [theme.breakpoints.up('md')]: {
              padding: '2rem',
              ':last-child': {
                padding: '2rem'
              }
            },
            [theme.breakpoints.down('md')]: {
              padding: '2rem',
              ':last-child': {
                padding: '2rem'
              }
            },
            [theme.breakpoints.down('sm')]: {
              padding: '1rem',
              ':last-child': {
                padding: '1rem'
              }
            }
          }
        }}
      >
        <CardContent>
          <Grid>
            <Typography variant='h4' color='#BBBBBB'>
              {texts('connection_code_section_title')}
            </Typography>
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              sx={{
                backgroundColor: 'rgba(187 187 187 / 0.2)',
                [theme.breakpoints.up('md')]: {
                  marginTop: '1rem',
                  paddingBlock: '1rem'
                },
                [theme.breakpoints.down('md')]: {
                  marginTop: '1rem',
                  paddingBlock: '.875rem'
                },
                [theme.breakpoints.down('sm')]: {
                  marginTop: '.75rem',
                  paddingBlock: '.75rem'
                }
              }}
            >
              <Typography
                variant='h1'
                component='p'
                sx={{
                  letterSpacing: 5
                }}
              >
                {props.connectionCode}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection='column'
            gap='1rem'
            sx={{
              [theme.breakpoints.up('sm')]: {
                marginTop: '1.5rem'
              },
              [theme.breakpoints.down('sm')]: {
                marginTop: '1rem'
              }
            }}
          >
            <Typography variant='h4' color='#BBBBBB'>
              {texts('send_invite_section_title')}
            </Typography>
            <Typography variant='body1'>
              {texts('send_invite_section_description')}
            </Typography>
            <Formik<InviteTraineeFormData>
              initialValues={{
                email: ''
              }}
              onSubmit={handleSubmit}
              validationSchema={inviteTraineeFormDataValidation(texts)}
            >
              <Form>
                <TextField
                  name='email'
                  label={texts('email_field_label')}
                />
                <Grid
                  container
                  marginTop='1rem'
                  justifyContent='flex-end'
                >
                  <LoadingButton
                    disableElevation
                    type='submit'
                    variant='contained'
                    sx={{
                      width: '7.5rem'
                    }}
                    loading={mutation.isLoading}
                  >
                    {texts('submit_button_label')}
                  </LoadingButton>
                </Grid>
              </Form>
            </Formik>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}