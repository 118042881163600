import { TFunction } from 'i18next'
import { Product } from '../api/data/types'
import { ProductFormData, ValidForUnit } from '../types/form/payments'
import { ProductDetails, ProductList, Product as ProductType } from '../types/product'
import { SelectOption } from '../types/common'

export function mapListDto(data: Product.ListDto): ProductList {
  return {
    totalCount: data.totalCount,
    products: data.products.map(mapListEntryDto)
  }
}

export function mapListEntryDto(pkg: Product.ProductDto): ProductType {
  return {
    ...pkg
  }
}

export function mapProductDetailsDto(pkg: Product.ProductDetailsDto): ProductDetails {
  return {
    ...pkg
  }
}

export function mapProductFormData(data: ProductFormData): Product.ProductBodyDto {
  return {
    count: data.count!,
    validFor: {
      value: data.validForCount!,
      unit: data.validForUnit
    },
    price: parseInt((parseFloat(data.price) * 100).toFixed(0)),
    taxRate: parseFloat(data.taxRate)
  }
}

export function mapProductDetailsDtoToForm(data: Product.ProductDetailsDto): ProductFormData {
  const isMonth = !(data.validFor % 30)
  return {
    count: data.count,
    validForCount: isMonth ? data.validFor / 30 : data.validFor,
    validForUnit: isMonth ? ValidForUnit.MONTH :ValidForUnit.DAY,
    price: (data.price / 100).toFixed(2),
    taxRate: `${data.taxRate}`
  }
}

export function mapProductToOption(product: ProductType, translations: TFunction): SelectOption {
  return {
    value: product.uuid,
    label: translations('addPackageDialog:product_option_label', {
      entryCount: translations('addPackageDialog:entry_count', { count: product.count }),
      validFor: !(product.validFor % 30)
        ? translations('addPackageDialog:valid_for_months', { months: (product.validFor / 30).toFixed() })
        : translations('addPackageDialog:valid_for_days', { days: product.validFor }),
    })
  }
}