import { Grid, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChartCardView from '../components/summaryPanel/ChartCardView'
import DataCardView from '../components/summaryPanel/DataCardView'
import BuyPackageDialogSection from '../components/summaryPanel/BuyPackageDialogSection'
import ReservationSection from '../components/summaryPanel/ReservationSection'
import useGetSessionData from '../hooks/data/session/useGetSessionData'
import { mapReservationArr } from '../mapping/reservation'
import { getPackageData } from '../redux/selectors'
import store from '../redux/store'
import { observeStore } from '../redux/utils'
import { Package } from '../types/account'
import { getActivePackage, getAccountDemoStatus, getDemoEntries } from '../services/storage/storageService'
import DemoAccountSection from '../components/summaryPanel/DemoAccountSection'

export default function SummaryPanel() {
  const theme = useTheme()
  const texts = useTranslation('summaryPanel').t

  const [activePackage, setActivePackage] = useState<Package | null>(getActivePackage() ?? null)
  const [demoEntries, setDemoEntries] = useState<number>(getDemoEntries() ?? 0)
  const [isDemoAccount, setIsDemoAccount] = useState<boolean>(getAccountDemoStatus() ?? true)

  const sessionData = useGetSessionData()

  useEffect(() => {
    const unsubscribe = observeStore(store, getPackageData, (state) => {
      setDemoEntries(state.demoEntries)
      setIsDemoAccount(state.isDemoAccount)
      setActivePackage(state.activePackage)
    })
  
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid 
      width='100%' 
      paddingX='1rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingBlock: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingBlock: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '1.5rem'
        }
      }}
    >
      <Typography variant='h2'>
        {texts('title')}
      </Typography>
      {
        ((demoEntries === 0 || !isDemoAccount) && activePackage === null) &&
          <Grid marginTop='1.5rem'>
            <BuyPackageDialogSection
              demo={isDemoAccount}
            />
          </Grid>
      }
      {
        isDemoAccount && demoEntries > 0 && 
          <Grid marginTop='1.5rem'>
            <DemoAccountSection />
          </Grid>
      }
      <Grid
        display='grid'
        sx={{
          marginTop: '1.5rem',
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'calc((100% - 1.5rem) / 3) calc((100% - 1.5rem) / 3 * 2)',
            gridTemplateRows: 'repeat(3, 1fr)',
            columnGap: '1.5rem',
            rowGap: '.75rem'
          },
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: '1fr auto',
            columnGap: '1rem',
            rowGap: '1rem'
          },
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '100%',
            gridTemplateRows: '1fr 1fr 1fr auto',
            rowGap: '.75rem'
          }
        }}
      >
        <Grid
          sx={{
            [theme.breakpoints.up('md')]: {
              gridArea: '1 / 1 / 2 / 2'
            },
            [theme.breakpoints.down('md')]: {
              gridArea: '1 / 1 / 2 / 2'
            },
            [theme.breakpoints.down('sm')]: {
              gridArea: '1 / 1 / 2 / -1'
            }
          }}
        >
          <DataCardView 
            title={texts('active_trainees_label')}
            value={sessionData.data?.counts.trainees ?? 0}
            isLoading={sessionData.isFetching}
          />
        </Grid>
        <Grid
          sx={{
            [theme.breakpoints.up('md')]: {
              gridArea: '2 / 1 / 3 / 2'
            },
            [theme.breakpoints.down('md')]: {
              gridArea: '1 / 2 / 2 / 3'
            },
            [theme.breakpoints.down('sm')]: {
              gridArea: '2 / 1 / 3 / -1'
            }
          }}
        >
          <DataCardView 
            title={texts('trainees_entry_count_label')}
            value={sessionData.data?.counts.traineeEntries ?? 0}
            isLoading={sessionData.isFetching}
          />
        </Grid>
        <Grid
          sx={{
            [theme.breakpoints.up('md')]: {
              gridArea: '3 / 1 / -1 / 2'
            },
            [theme.breakpoints.down('md')]: {
              gridArea: '1 / 3 / 2 / 4'
            },
            [theme.breakpoints.down('sm')]: {
              gridArea: '3 / 1 / 4 / -1'
            }
          }}
        >
          <DataCardView 
            title={texts('entry_count_label')}
            value={sessionData.data?.counts.allEntries ?? 0}
            isLoading={sessionData.isFetching}
          />
        </Grid>
        <Grid
          sx={{
            [theme.breakpoints.up('md')]: {
              gridArea: '1 / 2 / -1 / -1'
            },
            [theme.breakpoints.down('md')]: {
              gridArea: '2 / 1 / 3 / -1'
            },
            [theme.breakpoints.down('sm')]: {
              gridArea: '4 / 1 / 5 / -1'
            }
          }}
        >
          <ChartCardView 
            trainees={sessionData.data?.trainees ?? []}
            isLoading={sessionData.isFetching}
          />
        </Grid>
      </Grid>
      <Grid marginTop='2rem'>
        <ReservationSection 
          scheduleDays={mapReservationArr(sessionData.data?.reservations ?? [])}
          isLoading={sessionData.isFetching}
        />
      </Grid>
    </Grid>
  )
}
