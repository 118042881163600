import FacebookLogin, { FailResponse } from '@greatsumini/react-facebook-login'
import FacebookIconImage from '../../../assets/images/facebookIcon.png'
import OauthButton from './OauthButton'
import { OauthData } from '../../../types/common'
import { useFacebookLogin } from '../../../hooks/auth/useFacebookLogin'
import { isAuthError } from '../../../services/errors/utils'
import { AuthSubcodes } from '../../../services/errors/consts'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

type Props = {
  onUserNotFound: (data: OauthData) => void
}

export default function FacebookOauthButton(props: Props) {
  const texts = useTranslation('errors').t
  
  const loginMutation = useFacebookLogin((error, variables) => {
    if (isAuthError(error, AuthSubcodes.USER_NOT_FOUND)) {
      props.onUserNotFound({ token: variables.token })
    }
  })

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FB_APP_ID!}
      render={(renderProps) => (
        <OauthButton
          icon={FacebookIconImage}
          bgColor='#4267B2'
          onClick={renderProps.onClick!}
        />
      )}
      onSuccess={(response) => {
        loginMutation.mutate({
          token: response.accessToken
        })
      }}
      onFail={(err: FailResponse) => {
        toast.error(texts(
          err.status === 'loginCancelled'
            ? 'social_account_login_cancelled'
            : 'social_account_not_available'
        ))
      }}
    />
  )
}