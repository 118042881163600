import { LoadingButton } from '@mui/lab'
import { Button, CircularProgress, DialogActions, Grid, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { AddPaidPackageFormData } from '../../types/form/trainees'
import { addPackageFormDataValidation } from '../../validations/trainee'
import BaseDialog from '../customMui/BaseDialog'
import FormikSelectField from '../customMui/FormikSelectField'
import useGetTrainerProductList from '../../hooks/data/product/useGetTrainerProductList'
import { mapProductToOption } from '../../mapping/product'
import { useAddPackageToTrainee } from '../../hooks/data/trainees/useAddPackageToTrainee'

type Props = {
  open: boolean
  uuid: string
  onClose: () => void
}

export default function AddPackageDialog(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('addPackageDialog').t

  const productList = useGetTrainerProductList({
    limit: parseInt(process.env.REACT_APP_PRODUCT_LIST_LIMIT!),
    offset: 0,
    enabled: props.open
  })

  const mutation = useAddPackageToTrainee()

  const handleSubmit = (data: AddPaidPackageFormData) => {
    mutation.mutate({
      userUuid: props.uuid,
      productUuid: data.package!
    }, {
      onSuccess: props.onClose
    })
  }

  return (
    <BaseDialog
      open={props.open}
      title={texts('title')}
      onCloseButtonClick={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          maxHeight: '90%',
          [theme.breakpoints.up('md')]: {
            minWidth: '37.5rem'
          },
          [theme.breakpoints.down('md')]: {
            minWidth: '32.5rem'
          },
          [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '100%',
            margin: '1rem'
          },
          '::-webkit-scrollbar': {
            width: '0.4rem',
          },
          '::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            borderRadius: '0.2rem'
          }
        }
      }}
    >
      {
        productList.isFetching &&
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            paddingY='1rem'
          >
            <CircularProgress 
              size={60} 
              thickness={1.5}
              disableShrink
            />
          </Grid>
      }
      {
        !productList.isFetching &&
          <Grid>
            <Formik<AddPaidPackageFormData>
              initialValues={{
                package: ''
              }}
              onSubmit={handleSubmit}
              enableReinitialize
              validationSchema={addPackageFormDataValidation(texts)}
            >
              {(formikProps) => (
                <Form>
                  <Grid marginTop='1.5rem'> 
                    <FormikSelectField
                      name='package'
                      label={texts('package_field_label')}
                      options={productList.data?.products.map(p => mapProductToOption(p, texts)) ?? []}
                    />
                  </Grid>
                  <DialogActions sx={{ marginTop: '1rem' }}>
                    <Button
                      variant='outlined'
                      size='medium'
                      onClick={props.onClose}
                      sx={{
                        width: '10rem',
                        height: '2rem'
                      }}
                    >
                      {texts('cancel_button_label')}
                    </Button>
                    <LoadingButton
                      variant='contained'
                      size='medium'
                      type='submit'
                      disabled={!formikProps.dirty || !formikProps.isValid}
                      loading={mutation.isLoading} 
                      sx={{
                        width: '10rem',
                        height: '2rem'
                      }}
                    >
                      {texts('submit_button_label')}
                    </LoadingButton>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Grid>
      } 
    </BaseDialog>
  )
}
