import { LocationOnOutlined } from '@mui/icons-material'
import { Button, Grid, Skeleton, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'
import LocationPlaceholderImage from '../../../assets/images/locationPlaceholder.webp'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { mapDistanceValue } from '../../../mapping/common'
import { Location } from '../../../types/locations'

type Props = {
  location: Location
}

export default function MapLocationCardView(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('locationList').t
  const navigate = useNavigate()
  
  const { isMobile } = useGetDeviceType()

  return (
    <Grid sx={{ borderRadius: '.5rem', overflow: 'hidden' }}>
      <Grid
        display='grid'
        sx={{
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto auto auto'
          },
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'auto 1fr',
            gridTemplateRows: 'auto auto'
          }
        }}
      >
        <Grid 
          sx={{ 
            position: 'relative',
            fontSize: 0,
            height: 'auto',
            [theme.breakpoints.up('md')]: {
              gridArea: '1 / 1 / 2 / -1',
              width: '100%'
            },
            [theme.breakpoints.down('md')]: {
              gridArea: '1 / 1 / 3 / 2',
              width: '16rem'
            },
            [theme.breakpoints.down('sm')]: {
              gridArea: '1 / 1 / 3 / 2',
              height: '100%',
              width: '5.25rem'
            }
          }}
        >
          {
            !isMobile && <Grid sx={{ paddingBottom: '56.16%' }} />
          }
          <LazyLoadImage
            src={props.location.photo?.thumbnail ?? props.location.photo?.original ?? LocationPlaceholderImage}
            placeholder={          
              <Skeleton
                variant='rectangular'
                width='100%'
                height='100%'
                sx={{
                  position: 'absolute',
                  inset: 0,
                  borderTopLeftRadius: '.5rem',
                  borderTopRightRadius: '.5rem',
                }}
              />
            }
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              inset: 0,
              ...(
                isMobile 
                ? { 
                    objectFit: 'cover' 
                  } 
                : {
                    borderTopLeftRadius: '.5rem',
                    borderTopRightRadius: '.5rem'
                  }
              )
            }}
          />
          {
            props.location.upcomingEvent != null && !isMobile &&
              <Grid
                sx={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  backgroundColor: 'rgba(0 0 0 / 0.8)',
                  paddingBlock: '.5rem',
                  paddingInline: '1rem',
                  borderRadius: '.25rem',
                }}
              >
                <Typography 
                  variant='badge'
                  sx={{
                    color: '#fff'
                  }}
                >
                  {texts(
                    'available_at',
                    {
                      date: texts(
                        'tile_date_label',
                        {
                          diff: moment(props.location.upcomingEvent.scheduledAt, 'YYYY-MM-DD').diff(moment().startOf('d'), 'd'),
                          date: moment(props.location.upcomingEvent.scheduledAt, 'YYYY-MM-DD').format('DD.MM.YYYY')
                        }
                      ),
                      time: props.location.upcomingEvent.startTime
                    }
                  )}
                </Typography>
              </Grid>
          }
          {
            props.location.distance != null &&
              <Grid
                container
                alignItems='center'
                sx={{
                  position: 'absolute',
                  right: 8,
                  bottom: 8,
                  backgroundColor: 'rgba(92 15 139 / 0.8)',
                  padding: '.25rem',
                  width: 'max-content',
                  color: '#fff !important',
                  borderRadius: '.25rem',
                  [theme.breakpoints.down('sm')]: {
                    display: 'none'
                  }
                }}
              >
                <LocationOnOutlined 
                  sx={{
                    fontSize: '1rem',
                    marginTop: '-.125rem'
                  }}
                />
                <Typography variant='body2'>
                  {mapDistanceValue(props.location.distance)}
                </Typography>
              </Grid>
          }
        </Grid>
        <Grid
          container
          flexDirection='column'
          sx={{
            maxWidth: '100%',
            overflow: 'hidden',
            [theme.breakpoints.up('md')]: {
              gridArea: '2 / 1 / 3 / -1',
              padding: '1rem',
              gap: '.5rem'
            },
            [theme.breakpoints.down('md')]: {
              gridArea: '1 / 2 / 2 / -1',
              padding: '1rem',
              gap: '.5rem'
            },
            [theme.breakpoints.down('sm')]: {
              gridArea: '1 / 2 / 2 / -1',
              padding: '.5rem',
              gap: '.25rem'
            }
          }}
        >
          {
            props.location.upcomingEvent != null && isMobile &&
              <Grid
                sx={{
                  backgroundColor: 'rgba(0 0 0 / 0.8)',
                  paddingBlock: '.25rem',
                  paddingInline: '.5rem',
                  borderRadius: '.25rem',
                  width: 'max-content'
                }}
              >
                <Typography 
                  variant='badge'
                  sx={{
                    color: '#fff'
                  }}
                >
                  {texts(
                    'available_at',
                    {
                      date: texts(
                        'tile_date_label',
                        {
                          diff: moment(props.location.upcomingEvent.scheduledAt, 'YYYY-MM-DD').diff(moment().startOf('d'), 'd'),
                          date: moment(props.location.upcomingEvent.scheduledAt, 'YYYY-MM-DD').format('DD.MM.YYYY')
                        }
                      ),
                      time: props.location.upcomingEvent.startTime
                    }
                  )}
                </Typography>
              </Grid>
          }
          <Typography
            variant='body1'
            fontWeight={700}
            sx={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              overflow: 'hidden'
            }}
          >
            {props.location.name}
          </Typography>
          <Typography 
            variant='body1'
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 'var(--max-lines)',
              WebkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              overflow: 'hidden',
              visibility: 'visible',
              height: 'calc(var(--lh) * var(--max-lines))',
              maxHeight: 'calc(var(--lh) * var(--max-lines))',
              [theme.breakpoints.up('sm')]: {
                '--max-lines': 2
              },
              [theme.breakpoints.down('sm')]: {
                '--max-lines': 1
              },
            }}
          >
            {texts(
              'address_label',
              {
                streetAddress: props.location.address.streetAddress,
                postalCode: props.location.address.postalCode,
                town: props.location.address.town
              }
            )}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent='flex-end'
          gap='1rem'
          sx={{
            paddingInline: '1rem',
            paddingBottom: '1rem',
            paddingTop: '.5rem',
            [theme.breakpoints.up('md')]: {
              gridArea: '3 / 1 / 4 / -1'
            },
            [theme.breakpoints.down('md')]: {
              gridArea: '2 / 2 / 3 / 3'
            }
          }}
        >
          <Button
            onClick={() => {
              navigate(`/locations/${props.location.uuid}/calendar`)
            }}
          >
            {texts('reserve_button')}
          </Button>
          <Button
            onClick={() => {
              navigate(`/locations/${props.location.uuid}`)
            }}
            sx={{
              color: '#66C4D6'
            }}
          >
            {texts('details_button')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}