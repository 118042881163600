import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { Reservation } from '../../../api/data/types'
import { mapListDto } from '../../../mapping/reservation'
import { ReservationList } from '../../../types/reservations'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
  onSuccess: (data: ReservationList) => void
}

export default function useGetReservationList(options: Reservation.ListOptionsDto & Options) {
  const texts = useTranslation().t
  const key = [
    queryNames.getReservationList,
    options.order,
    options.limit,
    options.offset,
    options.from,
    options.to,
    options.places,
    options.rooms,
    options.trainees
  ]
  const query = useQuery(key, async () => {
    const { data } = await dataApi.getReservationList(options)
    return mapListDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
