import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { TraineeDetails, TraineeReservation } from '../../../types/trainees'
import CancelReservationDialog from '../../dialogs/CancelReservationDialog'
import ReservationTileActions from './ReservationTileActions'
import { useQueryClient } from '@tanstack/react-query'
import { queryNames } from '../../../hooks/queries'

type Props = {
  reservation: TraineeReservation
}

export default function ReservationTile(props: Props) {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const texts = useTranslation('traineeDetails').t
  const navigate = useNavigate()

  const { uuid } = useParams<{ uuid?: string }>()

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)

  return (
    <>
      <CancelReservationDialog
        open={isCancelDialogOpen}
        uuid={props.reservation.uuid}
        date={props.reservation.event.startDate.format('DD.MM.YYYY')}
        time={props.reservation.event.formattedStartTime}
        onClose={() => { setIsCancelDialogOpen(false) }}
        onSuccess={() => {
          queryClient.setQueryData([queryNames.getTraineeDetails, uuid], (oldData?: TraineeDetails) => {
            if (!oldData) {
              return oldData
            }
            return {
              ...oldData,
              reservations: [...oldData.reservations].filter(reservation => reservation.uuid !== props.reservation.uuid)
            }
          })
        }}
      />
      <Card sx={{ position: 'relative', maxWidth: '100%' }}>
        <CardContent
          sx={{
            cursor: 'pointer'
          }}
          onClick={() => {
            navigate(`/trainees/${uuid}/reservations/${props.reservation.uuid}/details`)
          }}
        >
          <Grid
            container
            gap='1rem'
            flexWrap='nowrap'
          >
            <Grid
              container
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              gap='.25rem'
              flexShrink={0}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
                [theme.breakpoints.up('md')]: {
                  padding: '.5rem',
                  width: '6.25rem'
                },
                [theme.breakpoints.down('md')]: {
                  padding: '.5rem',
                  width: '6.25rem'
                },
                [theme.breakpoints.down('sm')]: {
                  padding: '.25rem',
                  width: '5.25rem'
                }
              }}
            >
              <Typography
                variant='body1'
                fontWeight={700}
              >
                {props.reservation.event.formattedStartTime}
              </Typography>
            </Grid>
            <Grid
              container
              flexDirection='column'
              justifyContent='center'
              gap='.5rem'
            >
              <Grid 
                container
                gap='.5rem'
                sx={{
                  [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingRight: '2rem'
                  },
                  [theme.breakpoints.down('md')]: {
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-start',
                    paddingRight: '2rem'
                  },
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-start',
                    paddingRight: '2rem'
                  }
                }}
              >
                <Grid 
                  container
                  maxWidth='max-content'
                  flexGrow={0}
                  alignItems='center'
                  gap='.5rem'
                  sx={{
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'row',
                      alignItems: 'center'
                    },
                    [theme.breakpoints.down('sm')]: {
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }
                  }}
                >
                  <Typography
                    variant='body1'
                    fontWeight={700}
                  >
                    {texts(
                      'location_label',
                      {
                        location: props.reservation.place.name,
                        room: props.reservation.trainingRoom.name
                      }
                    )}
                  </Typography>
                  <Typography variant='body1'>
                    {texts(
                      'address_label',
                      {
                        streetAddress: props.reservation.place.address.streetAddress,
                        postalCode: props.reservation.place.address.postalCode,
                        town: props.reservation.place.address.town
                      }
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <ReservationTileActions 
          reservation={props.reservation}
          onCancel={() => setIsCancelDialogOpen(true)}
        />
      </Card>
    </>
  )
}