import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Trainee } from '../../../api/data/types'
import { mapListDto } from '../../../mapping/trainees'
import { HttpError } from '../../../types/common'
import { TraineeList } from '../../../types/trainees'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
  onSuccess: (data: TraineeList) => void
}

export default function useGetTraineeList(options: Trainee.ListOptionsDto & Options) {
  const texts = useTranslation().t
  const key = [
    queryNames.getTraineeList,
    options.search,
    options.order,
    options.limit,
    options.offset
  ]

  const query = useQuery(key, async () => {
    const { data } = await dataApi.getTraineeList(options)
    return mapListDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onSuccess: options.onSuccess,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
