import { Trans, useTranslation } from 'react-i18next'
import Dialog from '../customMui/Dialog'

type Props = {
  open: boolean
  traineeName: string
  onClose: () => void
}

export default function NoActivePackageDialog(props: Props) {
  const texts = useTranslation('noActivePackageDialog').t

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey='noActivePackageDialog:title'/>}
      description={
        <Trans 
          i18nKey='noActivePackageDialog:description'
          values={{
            name: props.traineeName
          }}
        />
      }
      confirmButtonLabel={texts('confirm')}
      onCloseButtonClick={props.onClose}
      onConfirmButtonClick={props.onClose}
    />
  )
}
