import moment from 'moment'
import { AuthDto } from '../../api/auth/types'
import { AccountDto, UpdatePackageDto } from '../../api/types'
import { mapFullName } from '../../mapping/common'
import { mapAccountDto, mapAuthDto } from '../../mapping/storage'
import { Package } from '../../types/account'
import { Coordinates } from '../../types/common'
import { Account as StorageAccount, Coordinates as StorageCoordinates } from '../../types/storage'

export const USER_UUID = 'user_uuid'
export const USER_NAME = 'user_name'
export const USER_DATA = 'user_data'
export const USER_COORDINATES = 'user_coordinates'

export function setAuthData(data: AuthDto) {
  localStorage.setItem(USER_UUID, data.user.uuid)
  localStorage.setItem(USER_NAME, mapFullName(data.user.firstName, data.user.lastName))
  localStorage.setItem(USER_DATA, JSON.stringify(mapAuthDto(data)))
}

export function setUserData(data: AccountDto) {
  localStorage.setItem(USER_UUID, data.uuid)
  localStorage.setItem(USER_NAME, mapFullName(data.firstName, data.lastName))
  localStorage.setItem(USER_DATA, JSON.stringify(mapAccountDto(data)))
}

export function setPackageData(data: UpdatePackageDto) {
  setUserData({
    ...getUserData()!,
    ...data
  })  
}

export function clearUserData() {
  localStorage.removeItem(USER_UUID)
  localStorage.removeItem(USER_NAME)
  localStorage.removeItem(USER_DATA)
}

export function setUserName(name: string) {
  return localStorage.setItem(USER_NAME, name)
}

export function setUserCoordinates(coords: StorageCoordinates) {
  return localStorage.setItem(USER_COORDINATES, JSON.stringify(coords))
}

export function getUserName(): string | null {
  return localStorage.getItem(USER_NAME)
}

export function getDemoEntries(): number | null {
  return JSON.parse(localStorage.getItem(USER_DATA) ?? 'null')?.demoEntries ?? null
}

export function getAccountDemoStatus(): boolean {
  return JSON.parse(localStorage.getItem(USER_DATA) ?? 'null')?.demo ?? true
}

export function getActivePackage(): Package | null {
  return JSON.parse(localStorage.getItem(USER_DATA) ?? 'null')?.package ?? null
}

export function getUserData(): StorageAccount | null {
  return JSON.parse(localStorage.getItem(USER_DATA) ?? 'null')
}

export function getUserUuid(): string | null {
  return localStorage.getItem(USER_UUID)
}

export function getUserCoordinates(): Coordinates | null {
  const value = localStorage.getItem(USER_COORDINATES)
  const parsedValue: StorageCoordinates = value ? JSON.parse(value): null

  return parsedValue && moment(parsedValue.updatedAt * 1000).add(parseInt(process.env.REACT_APP_GEOLOCATION_EXPIRE_AFTER_S!), 'seconds').isSameOrAfter(moment())
    ? {
        lat: parsedValue.lat,
        lng: parsedValue.lng
      }
    : null
}

export function clearUserCache() {
  clearUserData()
}