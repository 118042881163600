import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/customMui/Loader'
import MainSection from '../../components/locations/details/MainSection'
import TopSection from '../../components/locations/details/TopSection'
import useGetPlaceDetails from '../../hooks/data/locations/useGetPlaceDetails'
import { queryNames } from '../../hooks/queries'
import { DataSubcodes } from '../../services/errors/consts'
import { isDataError } from '../../services/errors/utils'
import ConnectionError from '../Errors/ConnectionError'

export default function LocationDetails() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const details = useGetPlaceDetails(
    uuid ?? '',
    (error) => {
      if (isDataError(error, DataSubcodes.PLACE_NOT_FOUND)) {
        navigate('/404')
      }
    }
  )

  if (details.isFetching) {
    return (
      <Grid marginTop='10rem' width='100%' overflow='hidden'>
        <Loader />
      </Grid>
    )
  }

  if (details.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getPlaceDetails, uuid])
        }}
      />
    )
  }

  return (
    <Grid width='100%' paddingX='1rem'>
      <TopSection name={details.data!.name} />
      <MainSection location={details.data!}/>
    </Grid>
  )
}