import LoadingButton from '@mui/lab/LoadingButton'
import { Divider, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { EmailFormData } from '../../../../types/form/auth'
import { emailFormDataValidation } from '../../../../validations/auth'
import Link from '../../../customMui/Link'
import TextField from '../../../customMui/TextField'
import { useStartPwdReset } from '../../../../hooks/auth/useStartPwdReset'

type Props = {
  onSuccess: (email: string) => void
}

export default function ProvideEmail(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('provideEmail').t

  const mutation = useStartPwdReset()

  const handleSubmit = (data: EmailFormData) => {
    mutation.mutate(data.email.trim(), {
      onSuccess: () => {
        props.onSuccess(data.email)
      }
    })
  }

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
      gap='1rem'
      textAlign='center'
      paddingX='2rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '10rem',
          width: '30rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '4rem',
          paddingBottom: '2rem',
          width: '30rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '2rem',
          width: '100vw'
        }
      }}
    >
      <Typography 
        variant='h1' 
        marginBottom='1rem'
      >
        {texts('title')}
      </Typography>
      <Grid width='100%'>
        <Formik<EmailFormData>
          initialValues={{
            email: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={emailFormDataValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <TextField
                name='email'
                label={texts('email_field_label')}
                fullWidth
              />
              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                loading={mutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
              >
                {texts('submit_button_label')}
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid>
        <Divider 
          sx={{
            marginTop: '.5em', 
            marginBottom: '1rem',
            width: '100%'
          }}
        />
        <Typography 
          variant='body1'
          marginTop='1rem'
          paddingX='1.25rem'
        >
          {texts('return_to_login_question')}
          <Link to='/login'>
            {texts('return_to_login_link_label')}
          </Link>
        </Typography>
      </Grid> 
    </Grid>
  )
}