import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Account } from '../../../api/data/types'
import { mapFullName } from '../../../mapping/common'
import { mapAccountDto, mapAccountDto as mapAccountDtoForStorage } from '../../../mapping/storage'
import { updateUserData, updateUserName } from '../../../redux/storageToolkit'
import store from '../../../redux/store'
import { Account as AccountType } from '../../../types/account'
import { HttpError } from '../../../types/common'
import { SessionData } from '../../../types/session'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export function useChangePassword() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (body: Account.ChangePasswordDto) => {
    const { data } = await dataApi.changePassword(body)
    return data
  }, {
    onSuccess: (data) => {
      store.dispatch(updateUserName(mapFullName(data.data!.firstName, data.data!.lastName)))
      queryClient.setQueryData([queryNames.getAccountData], (oldData?: AccountType) => {
        if (!oldData) {
          return oldData
        }
        return mapAccountDto(data.data!)
      })
      queryClient.setQueryData([queryNames.getSessionData], (oldData?: SessionData) => {
        if (!oldData) {
          return oldData
        }
        return {
          ...oldData,
          account: mapAccountDto(data.data!)
        }
      })
      store.dispatch(updateUserData(mapAccountDtoForStorage(data.data!)))
      toast.success(texts('successes:password_updated'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}