import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ScheduleIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
    >
      <g id="ScheduleIcon" transform="translate(-12.000000, -12.000000)" fillRule="nonzero">
        <g id="scheduleIcon" transform="translate(12.000000, 12.000000)">
          <path d="M21.6,2.4 L20.4,2.4 L20.4,1.2 C20.4,0.48 19.92,0 19.2,0 C18.48,0 18,0.48 18,1.2 L18,2.4 L6,2.4 L6,1.2 C6,0.48 5.52,0 4.8,0 C4.08,0 3.6,0.48 3.6,1.2 L3.6,2.4 L1.2,2.4 C0.6,2.4 0,2.88 0,3.6 L0,20.4 C0,21.12 0.6,21.6 1.2,21.6 L8.52,21.6 C7.68,20.16 7.2,18.48 7.2,16.8 C7.2,11.52 11.52,7.2 16.8,7.2 C19.08,7.2 21.12,8.04 22.8,9.36 L22.8,3.6 C22.8,3 22.2,2.4 21.6,2.4 Z" id="Path"></path>
          <path d="M16.8,9.6 C12.84,9.6 9.6,12.84 9.6,16.8 C9.6,20.76 12.84,24 16.8,24 C20.76,24 24,20.76 24,16.8 C24,12.84 20.76,9.6 16.8,9.6 Z M19.2,18 L16.8,18 C16.08,18 15.6,17.52 15.6,16.8 L15.6,13.2 C15.6,12.48 16.08,12 16.8,12 C17.52,12 18,12.48 18,13.2 L18,15.6 L19.2,15.6 C19.92,15.6 20.4,16.08 20.4,16.8 C20.4,17.52 19.92,18 19.2,18 Z" id="Shape"></path>
        </g>
      </g>
    </SvgIcon>
  )
}