import { ArrowBack, Tune } from '@mui/icons-material'
import { Grid, IconButton, Popover, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { TrainingRoomOption } from '../../../types/filters'
import { CalendarFilterFormData } from '../../../types/form/filter'
import Badge from '../../customMui/Badge'
import FullScreenDialog from '../../customMui/FullScreenDialog'
import PillButton from '../../customMui/PillButton'
import FilterForm from './FilterForm'

type Props = {
  initValues?: CalendarFilterFormData
  onFilterChange: (data: CalendarFilterFormData) => void
  rooms: TrainingRoomOption[]
  isFilterPresent: boolean
  disabledRooms: boolean
}

const defaultInitialValues: CalendarFilterFormData = {
  rooms: [],
  timesOfDay: []
}

export default function FilterSection(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('locationCalendar').t

  const { isDesktop } = useGetDeviceType()
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [initialValues, setInitialValues] = useState<CalendarFilterFormData>(props.initValues ?? defaultInitialValues)

  const handleSubmit = (data: CalendarFilterFormData) => {
    setInitialValues(data)
    props.onFilterChange(data)
    handleClose()
  }

  return (
    <>
      {
        props.isFilterPresent
        ? <Badge>
            <PillButton
              variant='contained'
              onClick={handleClick}
              sx={{
                [theme.breakpoints.down('sm')]: {
                  marginBottom: '.875rem'
                }
              }}
            >
              <Tune 
                sx={{ 
                  marginRight: '.25rem',
                  marginTop: '-.125rem',
                  fontSize: '.875rem' 
                }} 
              />
              <Typography variant='button2'>
                {texts('filter_button_label')}
              </Typography>
            </PillButton>
          </Badge>
        : <PillButton
            variant='contained'
            onClick={handleClick}
            sx={{
              [theme.breakpoints.down('sm')]: {
                marginBottom: '.875rem'
              }
            }}
          >
            <Tune 
              sx={{ 
                marginRight: '.25rem',
                marginTop: '-.125rem',
                fontSize: '.875rem' 
              }} 
            />
            <Typography variant='button2'>
              {texts('filter_button_label')}
            </Typography>
          </PillButton>
      }
      
      {
        isDesktop &&
          <Popover 
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '.MuiPaper-root': {
                maxHeight: 'calc(100% - 20.25rem)'
              }
            }}
          >
            <Grid
              sx={{
                padding: '1rem',
                width: '23rem'
              }}
            >
              <FilterForm
                initValues={initialValues}
                onSubmit={handleSubmit}
                rooms={props.rooms}
                fixedRoom={props.disabledRooms}
              />
            </Grid>
          </Popover>
      }
      {
        !isDesktop &&
          <FullScreenDialog
            open={Boolean(anchorEl)}
          >
            <Grid marginBottom='1rem'>
              <IconButton onClick={() => { setAnchorEl(null) }}>
                <ArrowBack 
                  sx={{
                    color: '#000',
                    fontSize: '2rem',
                    padding: 0
                  }}
                />
              </IconButton>
            </Grid>
            <FilterForm
              initValues={initialValues}
              onSubmit={handleSubmit}
              rooms={props.rooms}
              fixedRoom={props.disabledRooms}
            />
          </FullScreenDialog>
      }
    </>
  )
}