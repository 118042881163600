import { Autocomplete, TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { SelectOption } from '../../types/common'

type Props = {
  name: string
  label: string
  options: SelectOption[]
  disabled?: boolean
  onChange?: (value: SelectOption) => void
}

export default function FormikSelectField(props: Props) {
  const texts = useTranslation().t
  const [field, meta, helpers] = useField(props.name)

  return (
    <Autocomplete<SelectOption, false, true, false> 
      {...field}
      {...props}
      value={props.options.find(e => e.value === field.value) ?? undefined}
      onChange={(_, val: SelectOption) => {
        helpers.setValue((val as SelectOption)?.value)
        props.onChange?.(val)
      }}
      noOptionsText={texts('common:no_options_placeholder')}
      fullWidth
      disabled={props.disabled}
      multiple={false}
      disableClearable
      renderInput={(params: TextFieldProps) => (
        <MuiTextField
          {...params}
          name={props.name}
          onBlur={field.onBlur}
          label={props.label}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && !!meta.error ? meta.error : ''}
        />
      )}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        } else {
          return option.i18 ? texts(option.i18!.text, option.i18!.params) as string : (option.label ?? '')
        }
      }}
    />
  )
}