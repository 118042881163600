import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, Grid, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseDialog from '../customMui/BaseDialog'
import Pincode from '../customReact/Pincode'
import { ProfileFormData } from '../../types/form/account'
import { useEditAccountData } from '../../hooks/data/account/useEditAccountData'
import { mapProfileFormData } from '../../mapping/account'
import { useResendEmailVerification } from '../../hooks/data/account/useResendEmailVerification'

type Props = {
  open: boolean
  account: ProfileFormData | null
  onClose: () => void
}

export default function ConfirmEmailChangeDialog(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('confirmEmailChangeDialog').t

  const mutation = useEditAccountData()
  const resendMutation = useResendEmailVerification()

  const [code, setCode] = useState<string>('')

  const handleConfirm = () => {
    mutation.mutate({
      ...mapProfileFormData(props.account!),
      code: code
    }, {
      onSuccess: () => {
        props.onClose()
      }
    })
  }

  return (
    <BaseDialog
      open={props.open}
      title={texts('title')}
      onCloseButtonClick={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          [theme.breakpoints.up('md')]: {
            minWidth: '37.5rem'
          },
          [theme.breakpoints.down('md')]: {
            minWidth: '32.5rem'
          },
          [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '80%'
          }
        }
      }}
    >
      <Grid marginTop='1rem'>
        <Typography variant='body1'>
          {texts('description')}
        </Typography>
      </Grid>
      <Grid
        marginTop='1.5rem'
        marginBottom='1.5rem'
      >
        <Pincode
          onChange={(code => { setCode(code) })}
        />
      </Grid>
      <Typography variant='body1'>
        {texts('resend_email_description')}
        <Typography 
          variant='textButton'
          marginLeft='.5rem'
          sx={{
            cursor: 'pointer'
          }}
          onClick={() => {
            if (!resendMutation.isLoading) {
              resendMutation.mutate(props.account!.email)
            }
          }}
        >
          {texts('resend_email_button_label')}
        </Typography>
      </Typography>
      <DialogActions sx={{ marginTop: '2rem' }}>
        <Button
          variant='outlined'
          size='medium'
          onClick={props.onClose}
          sx={{
            width: '10rem',
            height: '2rem'
          }}
        >
          {texts('cancel')}
        </Button>
        <LoadingButton
          variant='contained'
          size='medium'
          disabled={code.length !== 6}
          loading={mutation.isLoading}
          onClick={handleConfirm}
          sx={{
            width: '10rem',
            height: '2rem'
          }}
        >
          {texts('confirm')}
        </LoadingButton>
      </DialogActions>
    </BaseDialog>
  )
}
