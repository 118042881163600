import { Box, Button, Card, CardActions, CardContent, Grid, Skeleton, Typography } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import PlaceholderImage from '../../../assets/images/locationPlaceholder.webp'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PersonOutline } from '@mui/icons-material'
import moment from 'moment'
import { RoomDetails } from '../../../types/rooms'

type Props = {
  locationUuid: string
  room: RoomDetails
}

export default function RoomCardView(props: Props) {
  const navigate = useNavigate()
  const texts = useTranslation('locationDetails').t

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent 
        sx={{ 
          padding: 0,
          flexGrow: 1,
          ':last-child': { padding: 0 } 
        }}
      >
        <Grid 
          sx={{ 
            position: 'relative',
            fontSize: 0,
            width: '100%',
            height: 'auto',
            aspectRatio: '1.78',
          }}
        >
          <Grid sx={{ paddingBottom: '56.16%' }} />
          <LazyLoadImage
            src={props.room.photo?.thumbnail ?? props.room.photo?.original ?? PlaceholderImage}
            placeholder={          
              <Skeleton
                variant='rectangular'
                width='100%'
                height='100%'
                sx={{
                  position: 'absolute',
                  inset: 0,
                  borderTopLeftRadius: '.5rem',
                  borderTopRightRadius: '.5rem'
                }}
              />
            }
            style={{
              width: '100%',
              height: '100%',
              borderTopLeftRadius: '.5rem',
              borderTopRightRadius: '.5rem',
              position: 'absolute',
              inset: 0
            }}
          />
          {
            props.room.upcomingEvents.length > 0 &&
              <Grid
                sx={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  backgroundColor: 'rgba(0 0 0 / 0.8)',
                  paddingBlock: '.5rem',
                  paddingInline: '.75rem',
                  borderRadius: '.25rem',
                  maxWidth: 'calc(100% - 1rem)'
                }}
              >
                <Typography 
                  variant='badge'
                  sx={{
                    color: '#fff'
                  }}
                >
                  {texts(
                    'available_at',
                    {
                      date: texts(
                        'tile_date_label',
                        {
                          diff: moment(props.room.upcomingEvents[0].scheduledAt, 'YYYY-MM-DD').diff(moment().startOf('d'), 'd'),
                          date: moment(props.room.upcomingEvents[0].scheduledAt, 'YYYY-MM-DD').format('DD.MM.YYYY')
                        }
                      ),
                      time: props.room.upcomingEvents
                        .filter(d => d.scheduledAt === props.room.upcomingEvents[0].scheduledAt)
                        .slice(0, 3)
                        .map(d => d.startTime)
                        .join(' | ')
                    }
                  )}
                </Typography>
              </Grid>
          }
          <Grid
            container
            alignItems='center'
            gap='.125rem'
            sx={{
              position: 'absolute',
              right: 8,
              bottom: 8,
              backgroundColor: 'rgba(92 15 139 / 0.8)',
              padding: '.25rem',
              width: 'max-content',
              color: '#fff !important',
              borderRadius: '.25rem'
            }}
          >
            <PersonOutline 
              sx={{
                fontSize: '1rem',
                marginTop: '-.125rem'
              }}
            />
            <Typography 
              variant='body2'
              fontWeight='bold'
              sx={{
                color: '#fff'
              }}
            >
              {texts('number_of_seats', { seats: props.room.seats })}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          flexDirection='column'
          gap='.5rem'
          padding='1rem'
          flexGrow={1}
        >
          <Typography
            variant='body1'
            fontWeight={700}
          >
            {props.room.name}
          </Typography>
          <Grid
            container
            alignItems='center'
            gap='.5rem'
          >
            {
              props.room.equipments.map(item => (
                <Box
                  key={item.uuid}
                  component='img'
                  src={item.photo.original}
                  width='1.5rem'
                  height='1.5rem'
                />
              ))
            }
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          justifyContent='flex-end'
          gap='1rem'
          sx={{
            paddingInline: '1rem',
            paddingBottom: '1rem',
            paddingTop: '.5rem'
          }}
        >
          <Button
            onClick={() => {
              navigate(`/locations/${props.locationUuid}/trainingroom/${props.room.uuid}/calendar`)
            }}
          >
            {texts('reserve_button')}
          </Button>
        </Grid>
      </CardActions>
    </Card>
  )
}