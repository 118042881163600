import { CalendarMonthOutlined, ConfirmationNumberOutlined, ReceiptOutlined } from '@mui/icons-material'
import { Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import InfoBlock from './InfoBlock'
import { Package } from '../../../types/account'
import { useState } from 'react'
import AddPackageDialog from '../../dialogs/AddPackageDialog'
import { useParams } from 'react-router'

type Props = {
  isStripeAvailable: boolean
  package: Package | null
  stripeId: string | null
}

export default function PackageSection(props: Props) {
  const texts = useTranslation('traineeDetails').t
  const theme = useTheme()
  const { isDesktop } = useGetDeviceType()

  const { uuid } = useParams<{ uuid: string }>()

  const [isAddPackageDialogOpen, setIsAddPackageDialogOpen] = useState<boolean>(false)

  return (
    <>
      <AddPackageDialog
        open={isAddPackageDialogOpen}
        uuid={uuid!}
        onClose={() => {
          setIsAddPackageDialogOpen(false)
        }}
      />
      <Grid
        sx={{
          [theme.breakpoints.up('md')]: {
            marginTop: '.6rem'
          },
          [theme.breakpoints.down('md')]: {
            marginTop: 0
          }
        }}
      >
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h3'>
            {texts('package_title')}
          </Typography>
        </Grid>
        <Card
          sx={{
            [theme.breakpoints.up('md')]: {
              marginTop: '1rem',
            },
            [theme.breakpoints.down('md')]: {
              marginTop: '.75rem',
            },
            [theme.breakpoints.down('sm')]: {
              marginTop: '.5rem',
            }
          }}
        >
          <CardContent>
            <Grid
              container
              flexDirection='column'
              gap='1.5rem'
            >
              <Grid>
                <Typography
                  variant={isDesktop ? 'h5' : 'h4'}
                  color='#BBBBBB'
                >
                  {texts('active_package_section_title')}
                </Typography>
                {
                  props.isStripeAvailable && props.package != null &&
                    <>
                      <InfoBlock icon={ConfirmationNumberOutlined}>
                        <Grid 
                          gridArea='1 / 2 / 2 / 3'
                          container
                          alignItems='center'
                        >
                          <Typography 
                            variant='body1'
                            fontWeight={700}
                          >
                            {texts('entries_count_left')}
                          </Typography>
                        </Grid>
                        <Grid gridArea='2 / 2 / 3 / 3'>
                          {`${props.package.leftEntries}/${props.package.totalEntries}`}
                        </Grid>
                      </InfoBlock>
                      <InfoBlock icon={CalendarMonthOutlined}>
                        <Grid 
                          gridArea='1 / 2 / 2 / 3'
                          container
                          alignItems='center'
                        >
                          <Typography 
                            variant='body1'
                            fontWeight={700}
                          >
                            {texts('package_valid_for')}
                          </Typography>
                        </Grid>
                        <Grid gridArea='2 / 2 / 3 / 3'>
                          {texts('until_date', { date: moment(props.package.validUntil).format('DD.MM.YYYY') })}
                        </Grid>
                      </InfoBlock>
                    </>
                }
                {
                  props.isStripeAvailable && props.package == null &&
                    <Grid
                      sx={{
                        [theme.breakpoints.up('sm')]: {
                          marginTop: '1rem'
                        },
                        [theme.breakpoints.down('sm')]: {
                          marginTop: '.75rem'
                        },
                      }}
                    >
                      <Typography
                        variant='body1'
                        color='#BBBBBB'
                      >
                        {texts('no_active_package')}
                      </Typography>
                    </Grid>
                }
                {
                  !props.isStripeAvailable &&
                    <Grid
                      sx={{
                        [theme.breakpoints.up('sm')]: {
                          marginTop: '1rem'
                        },
                        [theme.breakpoints.down('sm')]: {
                          marginTop: '.75rem'
                        },
                      }}
                    >
                      <Typography
                        variant='body1'
                        color='#BBBBBB'
                      >
                        {texts('payments_not_active')}
                      </Typography>
                    </Grid>
                }
              </Grid>
              <Grid>
                <Typography
                  variant={isDesktop ? 'h5' : 'h4'}
                  color='#BBBBBB'
                >
                  {texts('additional_actions_section_title')}
                </Typography>
                <Grid
                  sx={{
                    [theme.breakpoints.up('sm')]: {
                      marginTop: '1rem'
                    },
                    [theme.breakpoints.down('sm')]: {
                      marginTop: '.75rem'
                    },
                  }}
                  container
                  flexDirection='column'
                  gap='1.5rem'
                >
                  <Button
                    variant='text'
                    disabled={!props.isStripeAvailable}
                    onClick={() => {
                      window.open(`${process.env.REACT_APP_STRIPE_DASHBOARD_BASE_URL}customers/${props.stripeId}`, '_blank')
                    }}
                  >
                    <Grid 
                      container
                      alignItems='center'
                      gap='.25rem'
                    >
                      <ReceiptOutlined sx={{ marginTop: '-.125rem' }} />
                      {texts('transaction_history_button_label')}
                    </Grid>
                  </Button>
                  <Button
                    variant='text'
                    disabled={!props.isStripeAvailable || props.package != null}
                    onClick={() => {
                      setIsAddPackageDialogOpen(true)
                    }}
                  >
                    <Grid 
                      container
                      alignItems='center'
                      gap='.25rem'
                    >
                      <ReceiptOutlined sx={{ marginTop: '-.125rem' }} />
                      {texts('add_paid_package_button_label')}
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}
