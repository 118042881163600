import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { mapSessionDataDto } from '../../../mapping/session'

const dataApi = DataApi.getInstance()

export default function useGetSessionData() {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getSessionData], async () => {
    const { data } = await dataApi.getSessionData() 
    return mapSessionDataDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
