import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import AuthApi from '../../api/auth/AuthApi'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'
import { PasswordDto } from '../../api/auth/types'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'

const authApi = AuthApi.getInstance()

type MutationParams = {
  data: PasswordDto
  token: string
}

export function useFinishPwdReset() {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const mutation = useMutation(async (body: MutationParams) => {
    const { data } = await authApi.finishPasswordReset(body.data, body.token)
    return data
  }, {
    onSuccess: () => {
      toast.success(texts('successes:finish_pwd_reset'))
      navigate('/login')
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}