import { useEffect } from 'react'
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'

type Props = {
  children?: JSX.Element | JSX.Element[]
  containerId: string
}

function useBackButton() {
  const navType = useNavigationType()
  return navType === NavigationType.Pop
}

export default function ScrollToTop({ children, containerId }: Props) {
  const { pathname } = useLocation()

  const isPop = useBackButton()
  
  const { isDesktop } = useGetDeviceType()

  useEffect(() => {
    if (isPop) {
      return
    }
    if (isDesktop) {
      return document.getElementById(containerId)?.scrollTo(0, 0)
    }
    window.scrollTo(0, 0)
  }, [pathname, isPop, containerId])
  
  return (
    <>
      {
        children
      }
    </>
  )
}