import { Card, CardContent } from '@mui/material'
import { LocationDetails } from '../../../types/locations'
import InfoCardViewContent from './InfoCardViewContent'

type Props = {
  location: LocationDetails
}

export default function InfoCardView(props: Props) {
  return (
    <Card>
      <CardContent>
        <InfoCardViewContent location={props.location} />
      </CardContent>
    </Card>
  )
}