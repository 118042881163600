import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { Common } from '../../../api/data/types'

const dataApi = DataApi.getInstance()

export function useInviteTrainee() {
  const texts = useTranslation().t

  const mutation = useMutation(async (email: Common.EmailDto) => {
    const { data } = await dataApi.inviteTrainee(email)
    return data
  }, {
    onSuccess: () => {
      toast.success(texts('successes:trainee_invited'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}