import { KeyboardBackspace } from '@mui/icons-material'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { ReservationDetails } from '../../../types/reservations'
import ControlSection from './ControlSection'

type Props = {
  reservation: ReservationDetails
}

export default function TopSection(props: Props) {
  const theme = useTheme()
  const texts = useTranslation(['common', 'reservationDetails']).t
  const { isMobile } = useGetDeviceType()
  const navigate = useNavigate()

  return (
    <Grid
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: '1.5rem'
        }
      }}
    >
      <Button 
        variant='text'
        onClick={() => { navigate(-1)}}
      >
        <Grid
          container
          alignItems='center'
          gap='.5rem'
        >
          <KeyboardBackspace sx={{ marginTop: '-.125rem'}} />
          {texts('common:back')}
        </Grid>
      </Button>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        marginTop='1rem'
        flexWrap='nowrap'
      >
        <Typography variant='h2'>
          {texts('reservationDetails:title')}
        </Typography>
        {
          !isMobile &&
            <ControlSection reservation={props.reservation} />
        }
      </Grid>
    </Grid>
  )
}