import { Namespace, TFunction } from 'i18next'
import * as Yup from 'yup'
import { emailRegex, passwordAllowedSpecialCharactersRegex, phoneRegex } from './regexp'

export function changePwdFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    currentPassword: Yup.string()
      .required(t('validations:field_required')),
    newPassword: Yup.string()
      .required(t('validations:field_required'))
      .min(8, t('validations:password_min_length'))
      .matches(passwordAllowedSpecialCharactersRegex, t('validations:password_special_characters'))
      .max(32, t('validations:password_max_length'))
  })
}

export function profileFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    firstName: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_characters')),
    lastName: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_characters')),
    nick: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .max(64, t('validations:max_64_characters')),
    phone: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(phoneRegex, t('validations:phone'))
      .max(128, t('validations:max_128_characters')),
    email: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(emailRegex, t('validations:email'))
      .max(128, t('validations:max_128_characters'))
  })
}
