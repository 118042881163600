import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import BuyPackageDialog from '../dialogs/package/BuyPackageDialog'

type Props = {
  demo: boolean
}

export default function BuyPackageDialogSection(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('summaryPanel').t

  const [isBuyPackageDialogOpen, setIsBuyPackageDialogOpen] = useState<boolean>(false)

  return (
    <>
      <BuyPackageDialog
        demo={props.demo}
        open={isBuyPackageDialogOpen}
        onClose={() => { setIsBuyPackageDialogOpen(false) }}
      />
      <Grid
        container
        flexWrap='nowrap'
        gap='1rem'
        sx={{
          backgroundColor: 'rgba(255, 209, 0, 0.3)',
          borderRadius: '.25rem',
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            padding: '1.5rem 2rem'
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            padding: '1rem'
          }
        }}
      >
        <Grid flexGrow={1}>
          <Typography
            variant='body1'
            color='#EBB02D'
          >
            {texts(props.demo ? 'buy_first_package_description' : 'buy_package_description')}
          </Typography>
        </Grid>
        <Grid
          sx={{
            fontSize: 0 // Fix for weird space added above button (similar case with white space under img that sometimes shows)
          }}
        >
          <Button
            variant='text'
            sx={{
              color: '#EBB02D'
            }}
            onClick={() => { setIsBuyPackageDialogOpen(true) }}
          >
            <Typography
              variant='body1'
              color='#EBB02D'
              fontWeight={700}
              sx={{
                wordBreak: 'keep-all',
              }}
            >
              {texts(props.demo ? 'first_no_active_package_button_label' : 'no_active_package_button_label')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  )
}