import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import errors from './translations/errors.json'
import common from './translations/common.json'
import drawer from './translations/drawer.json'
import imgAlts from './translations/imgAlts.json'
import validations from './translations/validations.json'
import successes from './translations/successes.json'
import registrationMethods from './translations/pages/auth/registration/registrationMethods.json'
import introduceYourself from './translations/pages/auth/registration/introduceYourself.json'
import activateAccount from './translations/pages/auth/registration/activateAccount.json'
import login from './translations/pages/auth/login.json'
import provideEmail from './translations/pages/auth/pwdReset/provideEmail.json'
import verifyCode from './translations/pages/auth/pwdReset/verifyCode.json'
import finishPasswordReset from './translations/pages/auth/pwdReset/finishPasswordReset.json'
import cancelReservationDialog from './translations/dialogs/cancelReservationDialog.json'
import confirmReservationDialog from './translations/dialogs/confirmReservationDialog.json'
import unlinkTraineeDialog from './translations/dialogs/unlinkTraineeDialog.json'
import joinProgramDialog from './translations/dialogs/joinProgramDialog.json'
import confirmEmailChangeDialog from './translations/dialogs/confirmEmailChangeDialog.json'
import editTraineeNoteDialog from './translations/dialogs/editTraineeNoteDialog.json'
import questionnaireAnswersDialog from './translations/dialogs/questionnaireAnswersDialog.json'
import buyPackageDialog from './translations/dialogs/buyPackageDialog.json'
import noEntriesLeftDialog from './translations/dialogs/noEntriesLeftDialog.json'
import removeProductDialog from './translations/dialogs/removeProductDialog.json'
import noActivePackageDialog from './translations/dialogs/noActivePackageDialog.json'
import reservationList from './translations/pages/reservationList.json'
import addPackageDialog from './translations/dialogs/addPackageDialog.json'
import reservationDetails from './translations/pages/reservationDetails.json'
import locationList from './translations/pages/locationList.json'
import locationCalendar from './translations/pages/locationCalendar.json'
import locationDetails from './translations/pages/locationDetails.json'
import traineeList from './translations/pages/traineeList.json'
import traineeDetails from './translations/pages/traineeDetails.json'
import inviteTrainee from './translations/pages/inviteTrainee.json'
import summaryPanel from './translations/pages/summaryPanel.json'
import profileSettings from './translations/pages/profileSettings.json'
import paymentsConfiguration from './translations/pages/paymentsConfiguration.json'
import productPage from './translations/pages/productPage.json'
import ICU from 'i18next-icu'

export const defaultNS = 'common'

export const resources = {
  pl: {
    errors,
    common,
    imgAlts,
    validations,
    successes,
    registrationMethods,
    introduceYourself,
    activateAccount,
    login,
    provideEmail,
    verifyCode,
    finishPasswordReset,
    drawer,
    reservationList,
    reservationDetails,
    locationList,
    locationCalendar,
    cancelReservationDialog,
    confirmReservationDialog,
    locationDetails,
    traineeList,
    unlinkTraineeDialog,
    traineeDetails,
    inviteTrainee,
    summaryPanel,
    joinProgramDialog,
    profileSettings,
    confirmEmailChangeDialog,
    editTraineeNoteDialog,
    questionnaireAnswersDialog,
    buyPackageDialog,
    noEntriesLeftDialog,
    paymentsConfiguration,
    removeProductDialog,
    productPage,
    noActivePackageDialog,
    addPackageDialog
  }
}

i18n
  .use(ICU)
  .use(initReactI18next).init({
    resources,
    lng: 'pl',
    keySeparator: false,
    ns: [
      'errors',
      'common',
      'imgAlts',
      'validations',
      'successes',
      'registrationMethods',
      'introduceYourself',
      'activateAccount',
      'login',
      'provideEmail',
      'verifyCode',
      'finishPasswordReset',
      'drawer',
      'reservationList',
      'reservationDetails',
      'locationList',
      'locationCalendar',
      'cancelReservationDialog',
      'confirmReservationDialog',
      'locationDetails',
      'traineeList',
      'unlinkTraineeDialog',
      'traineeDetails',
      'inviteTrainee',
      'summaryPanel',
      'joinProgramDialog',
      'profileSettings',
      'confirmEmailChangeDialog',
      'editTraineeNoteDialog',
      'questionnaireAnswersDialog',
      'buyPackageDialog',
      'noEntriesLeftDialog',
      'paymentsConfiguration',
      'removeProductDialog',
      'productPage',
      'noActivePackageDialog',
      'addPackageDialog'
    ],
    defaultNS,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['sup', 'strong']
    },
    interpolation: {
      escapeValue: false
    },
    returnNull: false
  })

export default i18n