import { useTranslation } from 'react-i18next'
import ErrorPage from './ErrorPage'
import { InternetErrorBase64Src } from '../../utils/const'
import { useTheme } from '@mui/system'

type Props = {
  onRefresh: () => void
}

export default function ConnectionError(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('errors').t

  return (
    <ErrorPage
      image={InternetErrorBase64Src}
      title={texts('connection_error_title')}
      description={texts('connection_error_subtitle')}
      button={{
        label: texts('connection_error_button_label'),
        onClick: props.onRefresh
      }}
      sx={{
        container: {
          [theme.breakpoints.up('md')]: {
            height: '100%',
            justifyContent: 'center',
            paddingInline: '1rem'
          },
          [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 6rem)',
            justifyContent: 'center',
            paddingInline: '1rem'
          }
        },
        img: {
          [theme.breakpoints.up('md')]: {
            marginBottom: '2rem',
            width: '21.875rem',
            height: '15rem'
          },
          [theme.breakpoints.down('md')]: {
            marginBottom: '1.5rem',
            width: '16.375rem',
            height: '11.25rem'
          },
          [theme.breakpoints.down('sm')]: {
            marginBottom: '1.5rem',
            width: '11rem',
            height: '7.5rem'
          }
        }
      }}
    />
  )
}
