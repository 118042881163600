import { Store } from '@reduxjs/toolkit'
import { State } from './store'

export function observeStore<T>(store: Store, select: (state: State) => T, onChange: (state: T) => void) {
  let currentState: T

  function handleChange() {
    let nextState = select(store.getState())
    if (nextState !== currentState) {
      currentState = nextState
      onChange(currentState)
    }
  }

  let unsubscribe = store.subscribe(handleChange)
  handleChange()
  return unsubscribe
}