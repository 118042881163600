import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { ProductList } from '../../../types/product'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export function useRemoveTrainerProduct() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (uuid: string) => dataApi.removeTrainerProduct(uuid), {
    onSuccess: (_, uuid) => {
      const queriesData = queryClient.getQueriesData<ProductList>([queryNames.getTrainerProductList])
        for (const queryData of queriesData) {
          queryClient.setQueryData(queryData[0], (oldData?: ProductList) => {
            if (!oldData) {
              return oldData
            }
            const newData = {
              ...oldData
            }
            const idx = newData.products.findIndex(p => p.uuid === uuid)
            if (idx > -1) {
              newData.totalCount--
              newData.products.splice(newData.products.findIndex(p => p.uuid === uuid), 1)
            }
            return newData
          })
        }
      toast.success(texts('successes:trainer_product_removed'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}