import { SvgIcon, SvgIconProps } from '@mui/material'

export default function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='25px'
      height='20px'
      viewBox='0 0 25 20'
    >
      <path d="M2.56423457,13.0115143 C2.71871975,13.0115143 2.74961678,12.9960657 2.88865345,12.9651687 L5.66938668,12.4090221 C5.96290852,12.3317795 6.25643036,12.1927428 6.48815813,11.961015 L13.223712,5.22546121 C14.2587627,4.1904105 14.2587627,2.41383093 13.223712,1.37878023 L12.6521168,0.776288028 C11.6170661,-0.258762676 9.82503802,-0.258762676 8.78998731,0.776288028 L2.05443348,7.52729038 C1.83815422,7.74356964 1.68366904,8.05253999 1.60642645,8.34606184 L1.01938277,11.1576921 C0.942140181,11.6829417 1.09662536,12.1927428 1.46738979,12.5635072 C1.76091163,12.8570291 2.19347014,13.0115143 2.56423457,13.0115143 Z M3.08948418,8.63958368 L9.82503802,1.88858132 C10.273045,1.4405743 11.0918165,1.4405743 11.524375,1.88858132 L12.1114187,2.47562501 C12.6366683,3.00087462 12.6366683,3.74240348 12.1114187,4.25220457 L5.39131336,11.0032069 L2.53333753,11.482111 L3.08948418,8.63958368 Z" id="Shape"></path>
      <path d="M13.5929265,14.4643839 L1.78787773,14.4643839 C1.3239937,14.4643839 1.02006968,14.7683079 1.02006968,15.232192 C1.02006968,15.696076 1.4039737,16 1.78787773,16 L13.5289425,16 C13.9928265,16 14.3767305,15.696076 14.3767305,15.232192 C14.3607345,14.7683079 13.9768305,14.4643839 13.5929265,14.4643839 Z" id="Path" ></path>
    </SvgIcon>
  )
}