import { AxiosError } from 'axios'
import { TFuncKey, Namespace } from 'i18next'
import { HttpError } from '../../types/common'
import { AuthSubcodes, Codes, DataSubcodes } from './consts'

export function mapHttpErrorMessage(e: AxiosError<HttpError>): TFuncKey<Namespace> {
  if (e.response?.data?.code === Codes.AUTH) {
    return handleAuthErrors(e)
  } else if (e.response?.data?.code === Codes.DATA) {
    return handleDataErrors(e)
  }

  return 'errors:something_went_wrong'
}

function handleAuthErrors(e: AxiosError<HttpError>): TFuncKey<Namespace> {
  let errorMessage: TFuncKey<Namespace> = 'errors:something_went_wrong'

  switch (e.response?.data!.subcode) {
    case AuthSubcodes.TOKEN_ALREADY_USED:
      errorMessage = 'errors:token_already_used'
      break
    case AuthSubcodes.USER_INACTIVE:
      errorMessage = 'errors:user_inactive'
      break
    case AuthSubcodes.UNAUTHORIZED:
      errorMessage = 'errors:unauthorized'
      break
    case AuthSubcodes.ACCOUNT_REMOVED:
      errorMessage = 'errors:account_removed'
      break
    case AuthSubcodes.INCORRECT_PWD:
      errorMessage = 'errors:wrong_current_password'
      break
    case AuthSubcodes.SAME_PWD_AS_PREVIOUS_ONE:
      errorMessage = 'errors:same_password_as_previous_one'
      break
    case AuthSubcodes.INVALID_CREDENTIALS:
      errorMessage = 'errors:invalid_credentials'
      break
    case AuthSubcodes.INVALID_TOKEN:
      errorMessage = 'errors:wrong_token'
      break
    case AuthSubcodes.INVALID_TOKEN_OR_USED:
      errorMessage = 'errors:invalid_token_or_used'
      break
    case AuthSubcodes.FORBIDDEN:
      errorMessage = 'errors:forbidden'
      break
    case AuthSubcodes.USER_ALREADY_EXISTS:
      errorMessage = 'errors:user_already_exists'
      break
    case AuthSubcodes.EMAIL_NOT_VERIFIED:
      errorMessage = 'errors:email_not_verified'
      break
    case AuthSubcodes.INCORRECT_CODE:
      errorMessage = 'errors:incorrect_code'
      break
    case AuthSubcodes.INVALID_CODE:
      errorMessage = 'errors:invalid_code'
      break
    case AuthSubcodes.CODE_ALREADY_USED:
      errorMessage = 'errors:code_already_used'
      break
    case AuthSubcodes.INVALID_CODE_OR_USED:
      errorMessage = 'errors:invalid_code_or_used'
      break
    case AuthSubcodes.SOCIAL_ACCOUNT_NOT_AVAILABLE:
      errorMessage = 'errors:social_account_not_available'
      break
  }

  return errorMessage
}

function handleDataErrors(e: AxiosError<HttpError>): TFuncKey<Namespace> {
  let errorMessage: TFuncKey<Namespace> = 'errors:something_went_wrong'

  switch (e.response?.data!.subcode) {
    case DataSubcodes.BAD_REQUEST:
      errorMessage = 'errors:something_went_wrong'
      break
    case DataSubcodes.PLACE_NOT_FOUND:
      errorMessage = 'errors:place_not_found'
      break
    case DataSubcodes.TRAINEE_NOT_FOUND:
      errorMessage = 'errors:trainee_not_found'
      break
    case DataSubcodes.MAX_TIME_RANGE_EXCEEDED:
      errorMessage = 'errors:max_time_range_exceeded'
      break
    case DataSubcodes.EVENT_NOT_FOUND:
      errorMessage = 'errors:event_not_found'
      break
    case DataSubcodes.RESERVATION_NOT_FOUND:
      errorMessage = 'errors:reservation_not_found'
      break
    case DataSubcodes.NO_EMPTY_SEATS:
      errorMessage = 'errors:no_empty_sears'
      break
    case DataSubcodes.EVENT_ALREADY_STARTED:
      errorMessage = 'errors:event_already_started'
      break
    case DataSubcodes.TRAINEE_NOT_LINKED_TO_TRAINER:
      errorMessage = 'errors:trainee_not_linked_to_trainer'
      break
    case DataSubcodes.NO_DEMO_ENTRIES_LEFT:
      errorMessage = 'errors:no_demo_entries_left'
      break
    case DataSubcodes.EMAIL_TAKEN:
      errorMessage = 'errors:data_email_taken'
      break
    case DataSubcodes.PLACE_INACTIVE:
      errorMessage = 'errors:place_inactive'
      break
    case DataSubcodes.TRAINING_ROOM_INACTIVE:
      errorMessage = 'errors:training_room_inactive'
      break
    case DataSubcodes.EVENT_AFTER_RESERVATION_WINDOW:
      errorMessage = 'errors:event_after_reservation_window'
      break
    case DataSubcodes.WRONG_STRIPE_SECRET_KEY:
      errorMessage = 'errors:wrong_stripe_secret_key'
      break //wrong_stripe_secret_key
  }

  return errorMessage
}