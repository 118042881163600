import { Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import RoomCardView from './RoomCardView'
import { RoomDetails } from '../../../types/rooms'

type Props = {
  locationUuid: string
  rooms: RoomDetails[]
}

export default function RoomListSection(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('locationDetails').t

  return (
    <Grid>
      <Typography variant='h3'>
        {texts('room_section_title')}
      </Typography>
      <Grid
        display='grid'
        sx={{
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            rowGap: '1.25rem',
            columnGap: '1.25rem',
            marginTop: '1rem'
          },
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            rowGap: '1.25rem',
            columnGap: '1.25rem',
            marginTop: '1rem'
          },
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
            rowGap: '.75rem',
            columnGap: '0',
            marginTop: '.75rem'
          }
        }}
      >
        {
          props.rooms.map(r => (
            <RoomCardView
              key={r.uuid}
              room={r}
              locationUuid={props.locationUuid}
            />
          ))
        }
      </Grid>
    </Grid>
  )
}