import { Grid, Pagination } from '@mui/material'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { reservationsPerPage } from '../../../pages/Reservations/ReservationList'
import { RadioButtonOption } from '../../../types/common'
import { ReservationList, ReservationListSearchParams } from '../../../types/reservations'
import EmptyListView from '../../common/EmptyListView'
import ReservationCardView from './ReservationCardView'
import { contentContainerId } from '../../../utils/const'

type Props = {
  data: ReservationList
}

function Container(props: { children: ReactNode | ReactNode[] }) {
  return (
    <Grid 
      container 
      flexDirection='column'
      width='100%'
      paddingY='1.5rem'
      gap='1.5rem'
    >
      {props.children}
    </Grid>
  )
}

export default function MainSection(props: Props) {
  const texts = useTranslation('reservationList').t
  const { isMobile } = useGetDeviceType()

  const sortOptions: RadioButtonOption[] = texts('sort_options', {
    returnObjects: true 
  }) as RadioButtonOption[]
  
  const [searchParams, setSearchParams] = useQueryParams<ReservationListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    from: withDefault(StringParam, undefined),
    to: withDefault(StringParam, undefined),
    locations: withDefault(StringParam, undefined),
    rooms: withDefault(StringParam, undefined),
    trainees: withDefault(StringParam, undefined),
    order: withDefault(StringParam, sortOptions[0].value)
  })

  const maxPage = useMemo(() => {
    return Math.ceil((props.data.totalCount ?? 0) / reservationsPerPage)
  }, [props.data.totalCount])

  useEffect(() => {
    document.getElementById(contentContainerId)!.scrollTo(0, 0)
  }, [searchParams.page])

  const onPageChange = (_: React.ChangeEvent<any>, value: number) => {
    setSearchParams({ page: value }, 'replaceIn')
  }

  if (props.data.reservations.length === 0) {
    return (
      <Container>
        <Grid marginTop='1rem' width='100%'>
          <EmptyListView 
            title={texts('no_data_title')}
            description={texts('no_data_description')}
          />
        </Grid>
      </Container>
    )
  }

  return (
    <Container>
      <Grid
        item
        container
        width='100%'
        flexDirection='column'
        gap='.75rem'
      >
        {
          props.data.reservations.map(e => <ReservationCardView key={e.uuid} reservation={e}/>)
        }
      </Grid>
      {
        maxPage > 1 &&
          <Pagination 
            count={maxPage}
            page={searchParams.page}
            color='secondary'
            onChange={onPageChange}
            sx={{
              alignSelf: 'center'
            }}
            siblingCount={isMobile ? 0 : 2}
          />
      }
    </Container>
  )
}