import { SvgIcon, SvgIconProps } from '@mui/material'

export default function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
    >
      <g id="logoutIcon" fillRule="nonzero" strokeWidth="0.5">
        <path d="M11,15 C9.346,15 8,13.654 8,12 C8,10.346 9.346,9 11,9 L16,9 L16,2.75 C16,1.233 14.767,0 13.25,0 L2.75,0 C1.233,0 0,1.233 0,2.75 L0,21.25 C0,22.767 1.233,24 2.75,24 L13.25,24 C14.767,24 16,22.767 16,21.25 L16,15 L11,15 Z" id="Path"></path>
        <path d="M18.617,16.924 C18.244,16.769 18,16.404 18,16 L18,13 L11,13 C10.448,13 10,12.552 10,12 C10,11.448 10.448,11 11,11 L18,11 L18,8 C18,7.596 18.244,7.231 18.617,7.076 C18.991,6.921 19.421,7.007 19.707,7.293 L23.707,11.293 C24.098,11.684 24.098,12.316 23.707,12.707 L19.707,16.707 C19.421,16.993 18.991,17.079 18.617,16.924 Z" id="Path"></path>
      </g>
    </SvgIcon>
  )
}