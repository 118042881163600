import { Add } from '@mui/icons-material'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ProductCardView from './ProductCardView'
import { ProductList } from '../../../types/product'
import { useNavigate } from 'react-router-dom'

type Props = {
  enabled: boolean
  products: ProductList
}

export default function MyProductsSection(props: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const texts = useTranslation('paymentsConfiguration').t

  return (
    <Grid>
      <Grid
        container
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Typography variant='h3'>
          {texts('my_products')}
        </Typography>
        <Button
          variant='contained'
          sx={{
            height: '2.25rem !important'
          }}
          disabled={!props.enabled}
          onClick={() => {
            navigate('/payments/product/create')
          }}
        >
          <Add 
            sx={{ 
              color: '#fff',
              marginTop: '-.125rem',
              [theme.breakpoints.up('md')]: {
                fontSize: '1.25rem'
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '1.25rem'
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem'
              }
            }} 
          />
          {texts('add_product_button_label')}
        </Button>
      </Grid>
      {
        !props.enabled &&
          <Grid marginY='1.5rem' textAlign='center'>
            <Typography variant='body1' color='#BBBBBB'>
              {texts('payments_disabled')}
            </Typography>
          </Grid>
      }
      {
        props.enabled && props.products.products.length === 0 &&
          <Grid marginY='1.5rem' textAlign='center'>
            <Typography variant='body1' color='#BBBBBB'>
              {texts('no_products')}
            </Typography>
          </Grid>
      }
      {
        props.enabled && props.products.products.length > 0 &&
          <Grid 
            marginY='1.5rem'
            container
            flexDirection='column'
            gap='.75rem'
            width='100%'
          >
            {
              props.products.products.map(p => (
                <ProductCardView
                  key={p.uuid}
                  product={p}
                />
              ))
            }
          </Grid>
      }
    </Grid>
  )
}