import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { CardActions, IconButton, Menu, MenuItem, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Reservation } from '../../types/reservations'

type Props = {
  reservation: Reservation
  onCancel: () => void
}

export default function ReservationCardViewActions(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('summaryPanel').t
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <CardActions
      sx={{
        position: 'absolute',
        top: 16,
        right: 16
      }}
    >
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        sx={{
          color: '#000',
          padding: 0
        }}
      >
        <MoreHorizIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '12rem',
          },
        }}
      >
        <MenuItem 
          onClick={() => {
            handleClose()
            props.onCancel()
          }}
          sx={{
            color: theme.palette.error.main
          }}
        >
          {texts('cancel_reservation_tile_menu_label')}
        </MenuItem>
      </Menu>
    </CardActions>
  )
}