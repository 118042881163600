import { Grid, useTheme } from '@mui/material'
import ContactDataSection from './ContactDataSection'
import { TraineeDetails } from '../../../types/trainees'
import ScheduleSection from './ScheduleSection'
import { mapTraineeReservationList } from '../../../mapping/trainees'
import NoteSection from './NoteSection'
import AISuggestionSection from './AISuggestionSection'
import PackageSection from './PackageSection'

type Props = {
  details: TraineeDetails
  isStripeAvailable: boolean
}

export default function MainSection(props: Props) {
  const theme = useTheme()

  return (
    <Grid
      display='grid'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingBlock: '1.5rem',
          gridTemplateColumns: 'repeat(3, 1fr)',
          columnGap: '1.5rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingBlock: '1.5rem',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gridTemplateRows: 'repeat(2, auto)',
          rowGap: '1.25rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '1rem',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gridTemplateRows: 'repeat(2, auto)',
          rowGap: '1.25rem'
        }
      }}
    >
      <Grid
        sx={{
          [theme.breakpoints.up('md')]: {
            gridArea: '1 / 1 / 2 / 3'
          },
          [theme.breakpoints.down('md')]: {
            gridArea: '2 / 1 / 3 / 2'
          }
        }}
      >
        <ScheduleSection
          scheduleDays={mapTraineeReservationList(props.details.reservations)}
        />
      </Grid>
      <Grid
        sx={{
          [theme.breakpoints.up('md')]: {
            gridArea: '1 / 3 / 2 / 4',
            paddingBottom: '1rem'
          },
          [theme.breakpoints.down('md')]: {
            gridArea: '1 / 1 / 2 / 2' 
          }
        }}
      >
        <Grid>
          <ContactDataSection details={props.details}/>
        </Grid>
        <Grid marginTop='1.5rem'>
          <PackageSection 
            isStripeAvailable={props.isStripeAvailable}
            stripeId={props.details.stripeId}
            package={props.details.package}
          />      
        </Grid>
        <Grid marginTop='1.5rem'>
          <AISuggestionSection 
            questionnaire={props.details.questionnaire}
          />
        </Grid>
        <Grid marginTop='1.5rem'>
          <NoteSection 
            uuid={props.details.uuid}
            note={props.details.note} 
          />
        </Grid>
      </Grid>
    </Grid>
  )
}