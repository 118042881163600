import { Trans, useTranslation } from 'react-i18next'
import { useCancelReservation } from '../../hooks/data/reservations/useCancelReservation'
import Dialog from '../customMui/Dialog'

type Props = {
  open: boolean
  uuid: string
  date: string
  time: string
  onClose: () => void
  onSuccess?: () => void
}

export default function CancelReservationDialog(props: Props) {
  const texts = useTranslation('cancelReservationDialog').t

  const mutation = useCancelReservation()

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey='cancelReservationDialog:title'/>}
      description={
        <Trans 
          i18nKey='cancelReservationDialog:description'
          values={{
            date: props.date,
            time: props.time
          }}
        />
      }
      warning={<Trans i18nKey='cancelReservationDialog:warning'/>}
      closeButtonLabel={texts('cancel')}
      confirmButtonLabel={texts('confirm')}
      loading={mutation.isLoading}
      onCloseButtonClick={props.onClose}
      onConfirmButtonClick={() => {
        mutation.mutate(props.uuid, {
          onSettled: props.onClose,
          onSuccess: props.onSuccess
        })
      }}
    />
  )
}
