import moment from 'moment'
import { CalendarDate } from '../types/calendar'

export function getDayHourList(): string[] {
  const hours: string[] = []
    for (const date = moment().startOf('d'); date.isSameOrBefore(moment().endOf('d')); date.add(1, 'h')) {
      hours.push(date.format('HH:mm'))
    }
    return hours
}

/**
 * 
 * @param from - start date in format DD.MM.YYYY
 * @param to - end date in format DD.MM.YYYY
 * @returns 
 */
export function getCalendarDateList(from: string, to: string): CalendarDate[] {
  const dates: CalendarDate[] = []
    for (const date = moment(from, 'DD.MM.YYYY').startOf('d'); date.isSameOrBefore(moment(to, 'DD.MM.YYYY').startOf('d')); date.add(1, 'd')) {
      dates.push({
        date: date.format('DD.MM.YYYY'),
        label: date.format('DD.MM'),
        dayOfWeek: date.isoWeekday()
      })
    }
    return dates
}