import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Place } from '../../../api/data/types'
import { mapListDto } from '../../../mapping/locations'
import { HttpError } from '../../../types/common'
import { LocationList } from '../../../types/locations'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
  onSuccess: (data: LocationList) => void
}

export default function useGetPlaceList(options: Place.ListOptionsDto & Options) {
  const texts = useTranslation().t
  const key = [
    queryNames.getLocationList,
    options.search,
    options.limit,
    options.offset,
    options.lat,
    options.lon
  ]
  const query = useQuery(key, async () => {
    const { data } = await dataApi.getPlaceList(options)
    return mapListDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
