import { createSelector } from '@reduxjs/toolkit'
import { State } from './store'

const selectUserData = (state: State) => state.storage.userData

export const getPackageData = createSelector(selectUserData, userData => {
  return {
    isDemoAccount : userData?.demo ?? true,
    activePackage: userData?.package ?? null,
    demoEntries: userData?.demoEntries ?? 0
  }
})