import { CircularProgress, Grid } from '@mui/material'

export default function Loader() {
  return (
    <Grid
      container
      width='100%'
      height='100%'
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress size={80} thickness={1.5}/>
    </Grid>
  )
}

