import { Card, CardContent, CircularProgress, Grid, Typography, useTheme } from '@mui/material'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale
} from 'chart.js'
import { ReactNode } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import useGetDeviceType, { Device } from '../../hooks/device/useGetDeviceType'
import { Trainee } from '../../types/session'
import EmptyListView from '../common/EmptyListView'
import { mapFullName } from '../../mapping/common'

type Props = {
  trainees: Trainee[]
  isLoading: boolean
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement
)

function getChartWidth(deviceType: Device, dataLength: number): string {
  switch (deviceType) {
    case Device.DESKTOP:
      return `${dataLength * 80 + 30}px`
    case Device.TABLET:
      return `${dataLength * 96 + 30}px`
    case Device.MOBILE:
      return `100%`
  }
}

function getChartHeight(deviceType: Device, dataLength: number): string {
  switch (deviceType) {
    case Device.DESKTOP:
      return '318px'
    case Device.TABLET:
      return '318px'
    case Device.MOBILE:
      return `${dataLength * 32 + 30}px`
  }
}

function Container(props: { children: ReactNode | ReactNode[], centered: boolean }) {
  const texts = useTranslation('summaryPanel').t
  const theme = useTheme()

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          height: 'calc(100% - 2rem)',
          flexDirection: 'column'
        }}
      >
        <Grid>
          <Typography 
            variant='h5'
            component='p'
            color='#BBBBBB'
          >
            {texts('trainees_entry_count_chart_label')}
          </Typography>
        </Grid>
        <Grid 
          container
          flexGrow={1}
          sx={{
            ...(
              props.centered
              ? {
                  justifyContent: 'center',
                  alignItems: 'center'
                }
              : {}
            ),
            [theme.breakpoints.up('sm')]: {
              marginTop: '2rem'
            },
            [theme.breakpoints.down('sm')]: {
              marginTop: '1.25rem'
            }
          }}
        >
          {props.children}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default function ChartCardView(props: Props) {
  const texts = useTranslation('summaryPanel').t
  const theme = useTheme()

  const { type, isMobile } = useGetDeviceType()

  if (props.isLoading) {
    return (
      <Container centered>
        <CircularProgress size={60} thickness={1.5}/>
      </Container>
    )
  }

  if (props.trainees.length === 0) {
    return (
      <Container centered>
        <EmptyListView
          title={texts('no_client_data_title')}
          description={texts('no_client_data_description')}
        />
      </Container>
    )
  }
  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
          height: '25rem'
        },
        [theme.breakpoints.down('md')]: {
          height: '25rem'
        },
        [theme.breakpoints.down('sm')]: {
          maxHeight: '19.75rem',
          height: 'unset'
        }
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          height: 'calc(100% - 2rem)',
          flexDirection: 'column',
          position: 'relative',
          maxWidth: '100%'
        }}
      >
        <Grid>
          <Typography 
            variant='h5'
            component='p'
            color='#BBBBBB'
          >
            {texts('trainees_entry_count_chart_label')}
          </Typography>
        </Grid>
        <Grid
          sx={{
            maxWidth: '100%',
            height: '100%',
            overflowX: isMobile ? 'hidden' : 'auto',
            overflowY: isMobile ? 'auto' : 'hidden',
            '::-webkit-scrollbar': {
              height: '0.4rem',
              width: '0.4rem',
            },
            '::-webkit-scrollbar-track': {
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              borderRadius: '0.2rem'
            },
            [theme.breakpoints.up('sm')]: {
              marginTop: '2rem'
            },
            [theme.breakpoints.down('sm')]: {
              marginTop: '1rem',
              maxHeight: '15.875rem'
            }
          }}
        >
          <Grid 
            flexGrow={1}
            sx={{
              position: 'relative',
              width: getChartWidth(type, props.trainees.length),
              [theme.breakpoints.up('sm')]: {
                height: '100%'
              },
              [theme.breakpoints.down('sm')]: {
                height: getChartHeight(type, props.trainees.length)
              }
            }}
          >
            {
              isMobile
              ? <Bar
                  width='100%'
                  height={getChartHeight(type, props.trainees.length)}
                  options={{
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    animation: {
                      duration: 0
                    },
                    scales: {
                      x: {
                        ticks: {
                          precision: 0
                        },
                      },
                      y: {
                        beforeUpdate(axis) {
                          const labels = axis.chart.data.labels as string[]
                          for (let i=0; i < labels.length; i++) {
                            const lbl = labels[i]
                            if (typeof lbl === 'string' && lbl.length > 18) {
                              labels[i] = lbl.substring(0, 15) + '...'
                            }
                          }
                        }
                      }
                    },
                  }}
                  data={{
                    labels: props.trainees.map(t => mapFullName(t.firstName, t.lastName)),
                    datasets: [
                      {
                        data: props.trainees.map(t => t.entries),                       
                        backgroundColor: theme.palette.secondary.main,
                        borderColor: theme.palette.secondary.main,
                        borderRadius: 4
                      }
                    ]
                  }}
                />
              : <Bar
                  width='100%'
                  height={getChartHeight(type, props.trainees.length)}
                  options={{
                    maintainAspectRatio: false,
                    indexAxis: 'x',
                    animation: {
                      duration: 0
                    },
                    scales: {
                      y: {
                        ticks: {
                          precision: 0
                        }
                      }
                    },
                  }}
                  data={{
                    labels: props.trainees.map(t => mapFullName(t.firstName, t.lastName)),
                    datasets: [
                      {
                        data: props.trainees.map(t => t.entries),
                        backgroundColor: theme.palette.secondary.main,
                        borderColor: theme.palette.secondary.main,
                        borderRadius: 4
                      }
                    ]
                  }}
                />
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}