import { Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoBlock from './InfoBlock'
import { AccountCircleOutlined, CallOutlined, EmailOutlined } from '@mui/icons-material'
import { TraineeDetails } from '../../../types/trainees'
import Link from '../../customMui/Link'
import UnlinkTraineeDialog from '../../dialogs/UnlinkTraineeDialog'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'

type Props = {
  details: TraineeDetails
}

export default function ContactDataSection(props: Props) {
  const texts = useTranslation('traineeDetails').t
  const theme = useTheme()
  const navigate = useNavigate()
  const { isDesktop } = useGetDeviceType()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const onWriteOff = () => {
    setIsDialogOpen(true)
  }

  return (
    <>
      <UnlinkTraineeDialog
        open={isDialogOpen}
        uuid={props.details.uuid}
        name={props.details.fullName}
        onClose={() => { setIsDialogOpen(false) }}
        onSuccess={() => { navigate('/trainees', { replace: true })}}
      />
      <Grid
        sx={{
          [theme.breakpoints.up('md')]: {
            marginTop: '.6rem'
          },
          [theme.breakpoints.down('md')]: {
            marginTop: 0
          }
        }}
      >
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h3'>
            {texts('contact_data_title')}
          </Typography>
          {
            !isDesktop &&
              <Button
                variant='outlined'
                size='medium'
                onClick={onWriteOff}
                sx={{
                  borderColor: theme.palette.error.main,
                  color: theme.palette.error.main,
                  height: '2.25rem !important',
                  ':hover': {
                    borderColor: theme.palette.error.main
                  }
                }}
              >
                {texts('write_off_button_label')}
              </Button>
          }
        </Grid>
        <Card
          sx={{
            [theme.breakpoints.up('md')]: {
              marginTop: '1rem',
            },
            [theme.breakpoints.down('md')]: {
              marginTop: '.75rem',
            },
            [theme.breakpoints.down('sm')]: {
              marginTop: '.5rem',
            }
          }}
        >
          <CardContent>
            <Grid
              container
              flexDirection='column'
              gap='1.5rem'
            >
              <Grid>
                <Typography
                  variant={isDesktop ? 'h5' : 'h4'}
                  color='#BBBBBB'
                >
                  {texts('name_section_title')}
                </Typography>
                <InfoBlock icon={AccountCircleOutlined}>
                  <Grid 
                    gridArea='1 / 2 / 2 / 3'
                    container
                    alignItems='center'
                  >
                    <Typography 
                      variant='body1'
                      fontWeight={700}
                    >
                      {props.details.fullName}
                    </Typography>
                  </Grid>
                </InfoBlock>
              </Grid>
              <Grid>
                <Typography
                  variant={isDesktop ? 'h5' : 'h4'}
                  color='#BBBBBB'
                >
                  {texts('phone_section_title')}
                </Typography>
                <InfoBlock icon={CallOutlined}>
                  <Grid 
                    gridArea='1 / 2 / 2 / 3'
                    container
                    alignItems='center'
                  >
                    <Link to={`tel:${props.details.contact.phone}`}>
                      <Typography variant='body1' fontWeight={700}>
                        {props.details.contact.phone}
                      </Typography>
                    </Link>
                  </Grid>
                </InfoBlock>
              </Grid>
              <Grid>
                <Typography
                  variant={isDesktop ? 'h5' : 'h4'}
                  color='#BBBBBB'
                >
                  {texts('email_section_title')}
                </Typography>
                <InfoBlock icon={EmailOutlined}>
                  <Grid 
                    gridArea='1 / 2 / 2 / 3'
                    container
                    alignItems='center'
                  >
                    <Link to={`mailto:${props.details.contact.email}`}> 
                      <Typography variant='body1' fontWeight={700}>
                        {props.details.contact.email}
                      </Typography>
                    </Link>
                  </Grid>
                </InfoBlock>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {
          isDesktop &&
            <Button
              variant='outlined'
              size='medium'
              onClick={onWriteOff}
              sx={{
                borderColor: theme.palette.error.main,
                color: theme.palette.error.main,
                width: '100%',
                marginTop: '1.5rem',
                height: '2.25rem !important',
                ':hover': {
                  borderColor: theme.palette.error.main
                }
              }}
            >
              {texts('write_off_button_label')}
            </Button>
        }
      </Grid>
    </>
  )
}
