import { Grid, Pagination } from '@mui/material'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { isSearchParamsValid, traineesPerPage } from '../../../pages/Trainee/TraineeList'
import { RadioButtonOption } from '../../../types/common'
import { Trainee, TraineeListListSearchParams } from '../../../types/trainees'
import EmptyListView from '../../common/EmptyListView'
import Loader from '../../customMui/Loader'
import TraineeCardView from './TraineeCardView'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'

function Container(props: { children: ReactNode | ReactNode[] }) {
  return (
    <Grid 
      container 
      flexDirection='column'
      width='100%'
      paddingY='1.5rem'
      gap='1.5rem'
    >
      {props.children}
    </Grid>
  )
}

export type Props = {
  totalCount: number
  trainees: Trainee[]
  isFetching: boolean
}

export default function MainSection(props: Props) {
  const texts = useTranslation('traineeList').t
  const { isMobile } = useGetDeviceType()
  
  const sortOptions: RadioButtonOption[] = texts('sort_options', {
    returnObjects: true 
  }) as RadioButtonOption[]

  const [searchParams, setSearchParams] = useQueryParams<TraineeListListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined),
    order: withDefault(StringParam, sortOptions[0].value)
  })

  const maxPage = useMemo(() => {
    return Math.ceil(props.totalCount / traineesPerPage)
  }, [props.totalCount])

  const onPageChange = (_: React.ChangeEvent<any>, value: number) => {
    setSearchParams({ page: value }, 'replaceIn')
  }

  if (props.isFetching) {
    return (
      <Container>
        <Grid marginTop='4rem' width='100%' overflow='hidden'>
          <Loader />
        </Grid>
      </Container>
    )
  }

  const isPageNotFound = searchParams.page > 1 && props.trainees.length === 0
  if (!isSearchParamsValid(searchParams, sortOptions) || isPageNotFound) {
    return <></>
  }

  if (props.trainees.length === 0) {
    return (
      <Container>
        <Grid marginTop='4rem' width='100%'>
          <EmptyListView 
            title={texts('no_data_title')}
            description={texts('no_data_description')}
          />
        </Grid>
      </Container>
    )
  }

  return (
    <Container>
      <Grid
        item
        container
        width='100%'
        flexDirection='column'
        gap='.75rem'
      >
        {
          props.trainees.map(e => <TraineeCardView key={e.uuid} trainee={e}/>)
        }
      </Grid>
      {
        maxPage > 1 &&
          <Pagination 
            count={maxPage}
            page={searchParams.page}
            color='secondary'
            onChange={onPageChange}
            sx={{
              alignSelf: 'center'
            }}
            siblingCount={isMobile ? 0 : 2}
          />
      }
    </Container>
  )
}