import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import SearchInput from '../../customMui/SearchInput'
import { TraineeListListSearchParams } from '../../../types/trainees'
import SortButton from './SortButton'
import { RadioButtonOption } from '../../../types/common'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { useNavigate } from 'react-router-dom'
import { queryNames } from '../../../hooks/queries'
import { useQueryClient } from '@tanstack/react-query'

export default function TopSection() {
  const navigate = useNavigate()
  const theme = useTheme()
  const texts = useTranslation('traineeList').t
  const queryClient = useQueryClient()
  const { isMobile } = useGetDeviceType()

  const sortOptions: RadioButtonOption[] = texts('sort_options', {
    returnObjects: true 
  }) as RadioButtonOption[]

  const [searchParams, setSearchParams] = useQueryParams<TraineeListListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined),
    order: withDefault(StringParam, sortOptions[0].value)
  })

  return (
    <Grid
      container
      flexDirection='column'
      gap='1.5rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: '1.5rem'
        }
      }}
    >
      <Grid
        container
        justifyContent='space-between'
      >
        <Typography variant='h2'>
          {texts('title')}
        </Typography>
        {
          !isMobile &&
            <Button
              variant='contained'
              onClick={() => { 
                navigate('/trainees/invite')
              }}
              sx={{
                width: '17.5rem'
              }}
            >
              {texts('new_trainee_button_label')}
            </Button>
        }
      </Grid>
      <Grid
        container
        justifyContent='space-between'
        alignItems='flex-end'
        sx={{
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
          },
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
          }
        }}
      >
        <Grid 
          sx={{ 
            [theme.breakpoints.up('md')]: {
              width: '29rem'
            },
            [theme.breakpoints.down('md')]: {
              width: '100%'
            }
          }}
        >
          <SearchInput
            placeholder={texts('search_input_placeholder')}
            initValue={searchParams.search ? decodeURI(searchParams.search) : undefined}
            onSearch={(search: string) => {
              queryClient.resetQueries([
                queryNames.getTraineeList,
                search ? encodeURI(search) : undefined
              ])
              setSearchParams({ 
                search: search ? encodeURI(search) : undefined, 
                page: 1
              }, 'replaceIn')
            }}
            onClear={() => {
              queryClient.resetQueries([
                queryNames.getTraineeList,
                undefined
              ])
              setSearchParams({ 
                search: undefined, 
                page: 1
              }, 'replaceIn')
            }}
          />
        </Grid>
        <Grid
          sx={{
            [theme.breakpoints.up('md')]: {
              marginBottom: '1rem',
              marginTop: 0
            },
            [theme.breakpoints.down('md')]: {
              marginBottom: '0',
              marginTop: '.5rem'
            }
          }}
        >
          <SortButton
            initValue={searchParams.order}
            onChange={(value) => {
              setSearchParams({
                order: value, 
                page: 1
              }, 'replaceIn')
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}