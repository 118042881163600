import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storageReducer, { StorageState } from './storageToolkit'

export type State = { storage: StorageState }

const reducer = combineReducers({
  storage: storageReducer
})

const store = configureStore({
  reducer
  // TODO when releasing add devTools: false
})

export default store