import { Card, CardContent, CircularProgress, Grid, Typography, useTheme } from '@mui/material'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'

type Props = {
  title: string
  value: number
  isLoading: boolean
}

export default function DataCardView(props: Props) {
  const theme = useTheme()

  const { isDesktop } = useGetDeviceType()

  return (
    <Card
      sx={{
        width: '100%',
        height: isDesktop ? '7.875rem' : '100%'
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          height: 'calc(100% - 2rem)',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
          },
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
          }
        }}
      >
        <Grid
          sx={{
            [theme.breakpoints.up('sm')]: {
              flexGrow: 0
            },
            [theme.breakpoints.down('sm')]: {
              flexGrow: 1
            }
          }}
        >
          <Typography 
            variant='h5'
            component='p'
            color='#BBBBBB'
            sx={{
              [theme.breakpoints.up('sm')]: {
                flexGrow: 0
              },
              [theme.breakpoints.down('sm')]: {
                flexGrow: 1
              }
            }}
          >
            {props.title}
          </Typography>
        </Grid>
        <Grid 
          container
          item
          sx={{
            [theme.breakpoints.up('sm')]: {
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'flex-end',
              paddingBottom: '1.5rem',
              minHeight: '4.5rem'
            },
            [theme.breakpoints.down('sm')]: {
              flexGrow: 0,
              justifyContent: 'flex-end',
              alignItems: 'center',
              minHeight: '2.375rem',
              width: 'max-content'
            }
          }}
        >
          {
            props.isLoading
            ? <CircularProgress size={30} thickness={1.5}/>
            : <Typography
                variant='body1'
                fontWeight={700}
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    fontSize: '2.25rem'
                  },
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '2rem'
                  }
                }}
              >
                {props.value > 0 ? props.value : '-'}
              </Typography>
          }
        </Grid>
      </CardContent>
    </Card>
  )
}